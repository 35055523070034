import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ReusableLink2  } from "../../../globals";
import Project from "../project";
import "./residential.css";

const resProjects = ["venao", "barahona", "greenhouse", "montelirio", "bordeaux", "cubitacondos", "cubitahouses", "parkvillage", "demetra", "arango", "pasco", "town", "pptc"];

const projects = [
    {
        "Tucan Towncenter":  ['/town','aaa_portfolioResPic9.jpg'],
        "Panama Pacific Town Center":  ['/pptc','aaa_portfolioResPic10.jpg'],
        "GreenHouse":    ['/greenhouse','aaa_portfolioPic1.jpg'],
    },
    {
        "Demetra Residential":  ['/demetra','aaa_portfolioResPic6.jpg'],
        "Cubita Condos": ['/cubitacondos','aaa_portfolioResPic4.jpg'],
        "Cubita Houses": ['/cubitahouses','aaa_portfolioResPic11.jpg'],
    },
    {
        "Arango": ['/arango','aaa_portfolioResPic7.jpg'],
        "Ojo de Agua":  ['/pasco','aaa_portfolioResPic8.jpg'],
        "Park Village":  ['/parkvillage','aaa_portfolioResPic5.jpg'],
    },
    {
        "Monte Lirio":   ['/montelirio','aaa_portfolioResPic2.jpg'],
        "Barahona":      ['/barahona','aaa_portfolioUrbPic1.jpg'],
        "Bordeaux":      ['/bordeaux','aaa_portfolioResPic3.jpg'],
    },
    {
        "Venao House":   ['/venao','aaa_portfolioResPic0.jpg'],
    }

]

export const ResMenu = (props) => {   
    // console.log(props.currentLang);
    return (
        <div className="exanetAnim1">
            <div className="categoryTitle goggleOpenSans">{props.currentLang === 1? 'Residential':'Residencial'}</div>
            <div className="row clearfix">
                { Object.keys(projects[0]).map(key => {return( <ReusableLink2 to={projects[0][key][0]} category={key} img={projects[0][key][1]} key={key} /> )})}
            </div> 
            <br/>              
            <div className="row clearfix">
                { Object.keys(projects[1]).map(key => {return( <ReusableLink2 to={projects[1][key][0]} category={key} img={projects[1][key][1]} key={key} /> )})}    
            </div> 
            <br/>               
            <div className="row clearfix"> 
                { Object.keys(projects[2]).map(key => {return( <ReusableLink2 to={projects[2][key][0]} category={key} img={projects[2][key][1]} key={key} /> )})}         
            </div>              
            <div className="row clearfix"> 
                { Object.keys(projects[3]).map(key => {return( <ReusableLink2 to={projects[3][key][0]} category={key} img={projects[3][key][1]} key={key} /> )})}         
            </div>              
            <div className="row clearfix"> 
                { Object.keys(projects[4]).map(key => {return( <ReusableLink2 to={projects[4][key][0]} category={key} img={projects[4][key][1]} key={key} /> )})}         
            </div>              
        </div>
    );
}

class Residential extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    render(props) { 
        return ( 
            <>
                {/* Xavier[15/Ago/2022]:  aqui se llama a  'project.jsx'  que esta en el directorio  comps/portfolio    */}
                { resProjects.map(x => { return(<Route path={"/"+x} key={x} render={(props)=> <Project {...props} projectCat="res/" projectName={x} currentLang={this.props.currentLang} />} /> )}) }
            </>                    
        );
    }
}
 
export default Residential;


