import React, { Component } from 'react';
// import { ReusableLink3, ReusableLink4, getDevProps  } from "../../globals";
// import Row2 from "./homeMainPic";
// import Row6 from "./Row6";

// ++++++++++++++++++++ slider
// eslint-disable-next-line
import Swiper from 'react-id-swiper';
import './reset.min.css';
import 'swiper/css/swiper.css';
import './slider.css';
// ++++++++++++++++++++ slider

import "./home.css";
import "./fonts.css";

/*
const Row1 = () => {
    return(
        <div id="aaa_intro" className="T400">&nbsp;&nbsp;&nbsp;We are an Architecture Studio dedicated to the urban, residential, commercial, scientific &amp; touristic design at small, medium and large scales. We care for the integration of each architectural object within the city and its public space, as well as for reinforcing its sense of belonging with its environment. We understand that proposing and sharing, rather than imposing, helps to facilitate the process that occurs within our Studio among clients, collaborators and executors, creating participation-cooperation spaces that are very useful for the project.</div>
    );
};

const Row3 = {
    "Harvard University - July 2017": ["aaa_newPic1.jpg"],
    "Integral Academy San Lucas":     ["aaa_newPic2.jpg"],
}
const Row4 = {
    "Prime 507":   ["aaa_newPic3.jpg","aaa_newPic3--tbp.jpg", "aaa_newPic3--spp.jpg"],
    "Mar del Sur": ["aaa_newPic4.jpg","aaa_newPic4--tbp.jpg","aaa_newPic4--spp.jpg"],
    "Green House": ["aaa_newPic5x.jpg","aaa_newPic5--tbp.jpg","aaa_newPic5--spp.jpg"],
}
const Row5 = () => {
    return(
        <div id="aaa_intro2">&nbsp;&nbsp;&nbsp;Integral Dynamics is key. Recreating, rephrasing, rethinking elements, ideas, concepts and/or references is what we are looking for when approaching the architectural assignment, once the feasibility of what is to be undertaken has been verified. Find balance in that search is what characterizes our daily exercise, apart from never taking any project as small, because the physical dimensions can not be equated to the impact on the human dimension.</div>
    );
};

const Row7 = {
    "La Plaza, Cubita": ["aaa_newPic8x.jpg","aaa_newPic8--tbp.jpg","aaa_newPic8--spp.jpg"],
    "EPK":              ["aaa_newPic9.jpg","aaa_newPic9--tbp.jpg","aaa_newPic9--spp.jpg"],
    "Cubita":           ["aaa_newPic10.jpg","aaa_newPic10--tbp.jpg","aaa_newPic10--spp.jpg"],
}
const Row8 = () => {
    return(
        <div id="aaa_mainVideo" className="aaaBG100100">
            <video width="100%" height="100%" controls poster="img/misc/videoposterxx.jpg" className="aaaBGCover">
                <source src="media/videos/AAA-Prime507.mp4" type="video/mp4" />
                Update the browser. Your current version does not support HMTL5 video. 
            </video>
        </div>
    );
}
*/


class Home extends Component {

    constructor (props){
        super(props);
        this.state = {
            device: 0,  // device: 0[desktop], 1[tbP], 2[spP]
            sliderOverlay: "",
            slider: "",
            email: "",
            dom: {
                vw: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
                vh: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
                landscape: false
            }
        };
        // this.setState(prevState => ({dom: {...prevState.dom, vw: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)} }));
        // this.setState(prevState => ({dom: {...prevState.dom, vh: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) } }));
        if(this.state.dom.vw > this.state.dom.vh){
            // this.setState(prevState => ({dom: {...prevState.dom, landscape: true } }));
            this.state.dom.landscape = true;
        }
    }    


    componentDidMount(){
        // UNCOMMENT THIS FOR SLIDER IN INDEX.HTML
        // this.setState({ sliderOverlay: document.getElementsByClassName('main-slider') });
        // setTimeout(()=>{ 
        //     this.state.sliderOverlay[0].classList.remove("cssHide"); 
        //     // this.state.sliderOverlay[1].classList.remove("cssHide"); 
        // },5);

        // UNCOMMENT THIS FOR SLIDER IN COMPONENT 
        // this.setState({ sliderOverlay: document.getElementById('sliderOverlay') });
        // setTimeout(()=>{ 
        //     this.state.sliderOverlay.classList.remove("cssHide"); 
        // },5);


        // ++++++++++++++++++++++++++++++ setting PATH  ++++++++++++++++++++++++++++++
        // let dir = "";
        // setTimeout(() => {
        //     if(!this.state.dom.landscape){
        //         console.log("portrait");
        //         if(this.state.dom.vw > 0 && this.state.dom.vw < 500){
        //             dir = "spp";
        //         }else if(this.state.dom.vw >= 500 && this.state.dom.vw < 780) {
        //             dir = "tbp";
        //         }
        //     }else{
        //         console.log("landscape");
        //         if(this.state.dom.vw > 0 &&  this.state.dom.vw < 750){
        //             dir = "spl";
        //         }else if(this.state.dom.vw >= 750 && this.state.dom.vw < 1050) {
        //             dir = "tbl";
        //         }           
        //     }
        //     this.setState(prevState => ({dom: {...prevState.dom, path: this.state.dom.path0+dir } }));
        // }, 5);
       
    }
   
   

    // picText = {
    //     row1: ["Harvard University - July 2017", "Integral Academy San Lucas"],
    //     row2: ["Prime 507", "Mar del Sur", "GreenHouse"],
    //     row3: ["NEW URBANISM - 25th Congress, May 2017"],
    //     row4: ["La Plaza, Cubita", "EPK", "Cubita"],
    // }


    // componentDidMount(){
        // let device = getDevProps();
        // device.desktop !== null ? this.setState({ device: 0 }) : ( device.tbP !== null ? this.setState({ device: 1 }) : this.setState({ device: 2 }) )
    // }
    componentWillUnmount() {}

    // dumbToggle = (props) => { console.log(props.currentTarget) }
  
    render(props) { 
        // console.log('ssssss = '+this.props.currentLang);

        // REACT SWIPER
        // Main Slider
        // eslint-disable-next-line
        const params = {
            loop: true,
            speed:1000,
            autoplay:{
                delay: 7000,
                disableOnInteraction: false
            },
            loopAdditionalSlides: 10,
            grabCursor: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
            init: function(){
                this.autoplay.start();
            },
            imagesReady: function(){
                this.el.classList.remove('loading');
                this.autoplay.start();
            },
            slideChangeTransitionEnd: function(){
                let swiper = this,
                    captions = swiper.el.querySelectorAll('.caption');
                for (let i = 0; i < captions.length; ++i) {
                captions[i].classList.remove('show');
                }
                swiper.slides[swiper.activeIndex].querySelector('.caption').classList.add('show');
            },
            progress: function(){
                let swiper = this;
                for (let i = 0; i < swiper.slides.length; i++) {
                let slideProgress = swiper.slides[i].progress,
                    innerOffset = swiper.width * 0.5,
                    innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-bgimg").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
                }
            },
            touchStart: function() {
                let swiper = this;
                for (let i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
                }
            },
            setTransition: function(speed) {
                let swiper = this;
                for (let i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-bgimg").style.transition =
                    speed + "ms";
                }
            }
            }
        };        


        // ++++++++++++++++++++++++++++++ setting PATH  ++++++++++++++++++++++++++++++
        let dir = "";
        // setTimeout(() => {
            if(!this.state.dom.landscape){
                // console.log("portrait");
                if(this.state.dom.vw > 0 && this.state.dom.vw < 500){
                    dir = "spp";
                }else if(this.state.dom.vw >= 500 && this.state.dom.vw < 780) {
                    dir = "tbp";
                }
            }else{
                // console.log("landscape");
                if(this.state.dom.vw > 0 &&  this.state.dom.vw < 750){
                    dir = "spl";
                }else if(this.state.dom.vw >= 750 && this.state.dom.vw < 1050) {
                    dir = "tbl";
                }           
            }
        // }, 5);
        
        // eslint-disable-next-line
        let path = "img/main_pic/init/" + dir;

        const prizeText = ['ARCHITECTURE NATIONAL PRIZE 2021', 'SAN LUCAS Integral Academy', 'PRÊMIO NACIONAL DE ARQUITETURA 2021', 'Academia Integral SÃO LUCAS'];

        return( 
            // <React.Fragment>
            <div className="exanet_relT0L0W100H100M0P0 sliderContainer" >
                {/* <!-- SLIDER --> */}
                <div className="prize">
                    <div id="premio">
                        <strong>
                            <span>{this.props.currentLang === 1? prizeText[0]:prizeText[2] }</span><br/>
                            <span>{this.props.currentLang === 1? prizeText[1]:prizeText[3] }</span><br/>
                        </strong>
                        <span>Nuevo Emperador</span>
                    </div>
                </div>
                {/* <!-- SLIDER --> */}
            </div>
            // </React.Fragment>   
        );
    }
}
 
export default Home;



// +++++++++++++++++++++++++++++++++++ SLIDER ORIGINAL WITH IMG +++++++++++++++++++++++++++++++++
/* 

<div className="swiper-container main-slider loading">
<div className="swiper-wrapper">
  <Swiper {...params}>
    <div className="swiper-slide">
        <figure className="slide-bgimg" style={{  backgroundImage: "url("+path+"/l1.jpg)" }}>
            <img alt=""   src={path+"/l1.jpg"} className="entity-img" />
        </figure>
        <div className="content">
            <p className="title">DEMETRA Residential</p>
            <span className="caption">Pueblo Nuevo</span>
        </div>
    </div>
    <div className="swiper-slide">
        <figure className="slide-bgimg" style={{  backgroundImage: "url("+path+"/l2.jpg)" }}>
            <img alt=""   src={path+"/l2.jpg"} className="entity-img" />
        </figure>
        <div className="content">
            <p className="title">Academia Integral San Lucas</p>
            <span className="caption">Nuevo emperador, Arraijan</span>
        </div>
    </div>
    <div className="swiper-slide">
        <figure className="slide-bgimg" style={{  backgroundImage: "url("+path+"/l8.jpg)" }}>
            <img alt=""   src={path+"/l8.jpg"} className="entity-img" />
        </figure>
        <div className="content">
            <p className="title">Ojo de Agua</p>
            <span className="caption">San Miguelito</span>
        </div>
    </div>
    <div className="swiper-slide">
        <figure className="slide-bgimg" style={{  backgroundImage: "url("+path+"/l5.jpg)" }}>
            <img alt=""   src={path+"/l5.jpg"} className="entity-img" />
        </figure>
        <div className="content">
            <p className="title">Panama Pacifico Retail</p>
            <span className="caption">Panama Pacifico</span>
        </div>
    </div>
    <div className="swiper-slide">
        <figure className="slide-bgimg" style={{  backgroundImage: "url("+path+"/l3.jpg)" }}>
            <img alt=""   src={path+"/l3.jpg"} className="entity-img" />
        </figure>
        <div className="content">
            <p className="title">The Grove</p>
            <span className="caption">Santa Maria Golf &amp; Country Club</span>
        </div>
    </div>                        
    <div className="swiper-slide">
        <figure className="slide-bgimg" style={{  backgroundImage: "url("+path+"/l7.jpg)" }}>
            <img alt=""   src={path+"/l7.jpg"} className="entity-img" />
        </figure>
        <div className="content">
        <p className="title">Primavera Village</p>
            <span className="caption">Chapala, Arraijan</span>
        </div>
    </div>                        
  </Swiper> 
</div>
<div className="swiper-button-prev swiper-button-white"></div>
<div className="swiper-button-next swiper-button-white"></div>
</div> 

*/