import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'; 
import "./header.css";

class Header extends Component {
    constructor (props){
        super(props);
        this.state = {
            dom: {
                aaaMenu: '',
                vw: 0,
                vh: 0,
                landscape: false,
                lang: 1
            }
        };
    }
    // setCurrentLang = (props)=> { this.props.setCurrentLang = this.state.dom.lang }

    componentDidMount(props){
        // console.log(this.props);
        this.setState(prevState => ({dom: {...prevState.dom, aaaMenu: document.getElementById('aaaMenu')} }));
        this.setState(prevState => ({dom: {...prevState.dom, vw: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)} }));
        this.setState(prevState => ({dom: {...prevState.dom, vh: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) } }));

        setTimeout(() => {
            if(this.state.dom.vw > this.state.dom.vh){
                this.setState(prevState => ({dom: {...prevState.dom, landscape: true } }));
            }
        }, 10);
    }

    toggleMenu = () => { 
        this.state.dom.aaaMenu.classList.toggle('toogleMenu');
    }

    toggleLang = (x) => {
        if(x !== this.state.dom.lang ){ this.setState(prevState => ({dom: {...prevState.dom, lang: x } })); }
        this.props.setCurrentLang(this.state.dom.lang);
        // this.setCurrentLang();
    }

    getLang = () => { return ({currentLang: 0})}
    
    configDOM = (e) => { 

        // HIDE MAGAZINES FRAME IF VISIBLE
        let pubs = document.getElementById('aaa_pubFrame');
        let magFrame = document.getElementById('magFrame');
        if(!pubs.classList.contains('cssHide')){
            pubs.classList.add('notVisible');
            pubs.classList.add('cssHide');
            magFrame.setAttribute('src', '');
        }
        
        // SLIDER - Fix Middle width/height
        let scroller = document.getElementById('aaa_scrollUp');
        let middle = document.getElementById('aaaMiddle');
        if(e === null){
            scroller.style.display = "block";
            middle.style.padding = "80px 18px 0 18px";
            middle.style.height = "auto";
            if(this.state.dom.vw > 570){
                if( this.state.dom.landscape ){
                    middle.style.width = "90%";
                }else{
                    middle.style.width = "85%";
                }
            }
        }
        if(e === "journal"){
            middle.style.padding = "80px 18px 0 18px";
            middle.style.maxWidth = "1500px";
        }else{
            middle.style.padding = "80px 18px 0 18px";
            middle.style.maxWidth = "1160px";
        }
        if( e === "slider" ){ 
            middle.style.width = "100%";
            middle.style.height = "100%";
            middle.style.maxWidth = "100%";   
            // middle.style.top = 0;   
            middle.style.padding = "0px 0px 0px 0px";   
            // scroller
            scroller.style.display = "none";
        }
    }

    render() { 
        let aaaLang = [];
        if(this.state.dom.lang === 1){
            aaaLang = ["Home", "Portfolio", "Studio", "Offices", "Careers", "Journal", "Publications", "Volunteering"];    
        }else{
            aaaLang = ["Início", "Portfólio", "Sobre nós", "Filiais", "Recrutamento", "Notícias", "Publicações", "Voluntariado" ];
        }
        return ( 
            <div id="aaaHeader">
                <div id="aaa3Bar" onClick={this.toggleMenu}><img src="img/misc/wlc3bars.png" alt="logo" width='24' height='24' /></div>
                <div id="aaaMenu" className="T200 clearfix toogleMenu">
                    <NavLink className="aaaNavlink" to={{ pathname: "/" }} onClick={()=>this.configDOM('slider')} >{ aaaLang[0] }</NavLink>
                    <NavLink className="aaaNavlink" to={{ pathname: "/portfolio", state: {currentLang: this.state.dom.lang} }}  onClick={()=>this.configDOM(null)} id="aaaPortfolio" >{ aaaLang[1] }</NavLink>
                    <NavLink className="aaaNavlink" to={{ pathname: "/studio",    state: {currentLang: this.state.dom.lang} }}  onClick={()=>this.configDOM(null)}     >{ aaaLang[2] }</NavLink>
                    <NavLink className="aaaNavlink" to={{ pathname: "/oficinas",  state: {currentLang: this.state.dom.lang} }}  onClick={()=>this.configDOM(null)}     >{ aaaLang[3] }</NavLink>
                    <NavLink className="aaaNavlink" to={{ pathname: "/careers",   state: {currentLang: this.state.dom.lang} }}  onClick={()=>this.configDOM(null)}     >{ aaaLang[4] }</NavLink>
                    <NavLink className="aaaNavlink" to={{ pathname: "/journal",   state: {currentLang: this.state.dom.lang} }}  onClick={()=>this.configDOM('journal')}>{ aaaLang[5] }</NavLink>
                    <NavLink className="aaaNavlink" to={{ pathname: "/pubs",      state: {currentLang: this.state.dom.lang} }}  onClick={()=>this.configDOM(null)}     >{ aaaLang[6] }</NavLink>
                    <NavLink className="aaaNavlink" to={{ pathname: "/volunteer", state: {currentLang: this.state.dom.lang} }}  onClick={()=>this.configDOM(null)}     >{ aaaLang[7] }</NavLink>
                    <div id="flags">
                        <div id="english"  className='' onClick={()=>this.toggleLang(1)}></div>
                        <div id="portugal" className='' onClick={()=>this.toggleLang(0)}></div>
                    </div>
                </div>
                {/* <div id="flags">
                    <div id="english"  className='' onClick={()=>this.toggleLang(1)}></div>
                    <div id="portugal" className='' onClick={()=>this.toggleLang(0)}></div>
                </div> */}
                <div id="aaaLogo">
                    <img src="img/logos/AAA-14feb-3.png" alt="logo" width='200' height='61' />
                </div>
            </div>
        );
    }
}
 
export default Header;