import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ReusableLink2 } from "../../../globals";
import Project from "../project";


const projects = [
    {
        "Cubita Hotel & Resort": ['/cubita','aaa_portfolioTouPic1.jpg'],
    }
]

const touProjects = ["cubita"];


export const TouMenu = () => {
    return (
        <div className="exanetAnim1">
            <div className="categoryTitle goggleOpenSans">Tourism</div>
            <div className="row clearfix">
                { Object.keys(projects[0]).map(key => {return( <ReusableLink2 to={projects[0][key][0]} category={key} img={projects[0][key][1]} key={key} /> )})}
            </div>                
        </div>
    );
}


class Tourism extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    render(props) { 
        return ( 
            <>
                { touProjects.map(x => { return(<Route path={"/"+x} key={x} render={(props)=> <Project {...props} projectCat="tou/" projectName={x} currentLang={this.props.currentLang} />} /> )}) }
            </>
        );
    }
}
 
export default Tourism;