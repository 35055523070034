import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import "./portfolio.css";
import ReusableLink from "../../globals";
import Residential, { ResMenu } from "./res/residential";
import Commercial, { ComMenu }  from "./com/commercial";
import Educational, { EduMenu } from "./edu/educational";
import Scientific, { SciMenu }  from "./sci/scientific";
import Tourism, { TouMenu }     from "./tou/tourism";
import Urbanism, { UrbMenu }    from "./urb/urbanism";
import Interiors, { IntMenu }   from "./int/interiors";


// const projects = [
//     {
//         "Residential": ['/res','aaa_portfolioPic1.jpg'],
//         "Commercial":  ['/com','aaa_portfolioPic2.jpg'],
//         "Scientific":  ['/sci','aaa_portfolioPic3.jpg']
//     },
//     {
//         "Tourism":   ['/tou','aaa_portfolioPic4.jpg'],
//         "Urbanism":  ['/urb','aaa_portfolioPic5.jpg'],
//         "Interiors": ['/int','aaa_portfolioPic6.jpg'],
//     },
//     {
//         "Educational": ['/edu','aaa_portfolioPic7.jpg'],
//     }
// ]

const projects2 = [
    {
        "res": {
            "id": ["Residential","Residencial"],
            "info": ['/res','aaa_portfolioPic1.jpg']
        },
        "com": {
            "id": ["Commercial","Comercial"],
            "info":['/com','aaa_portfolioPic2.jpg']
        },
        "sci": {
            "id": ["Scientific", "Científico"],
            "info":['/sci','aaa_portfolioPic3.jpg']
        }
    },
    {
        "tou": {
            "id": ["Tourism","Turismo"],
            "info": ['/tou','aaa_portfolioPic4.jpg']
        },
        "urb": {
            "id": ["Urbanism","Urbanismo"],
            "info": ['/urb','aaa_portfolioPic5.jpg']
        },
        "int": {
            "id": ["Interiors","Interiores"],
            "info": ['/int','aaa_portfolioPic6.jpg']
        }
    },
    {
        "edu": {
            "id": ["Educational","Educação"],
            "info": ['/edu','aaa_portfolioPic7.jpg']
        }
    }
]

const createContent2 = (x,i,y) => {
    return(
        <div className="row clearfix" key={i}>
            {/* ***** OJO:  ResuableLink is a customized component created by me, located in the globals.jsx file, below Apps.js ****** */}
            { Object.keys(x).map((e,key) => {return( <ReusableLink to={x[e].info[0]} category={y===1? x[e].id[0]:x[e].id[1]} img={x[e].info[1]} key={key} currentLang={y} /> )} )}
        </div>                    
    )
}


// const createContent = (x,i,y) => {
//     return(
//         <div className="row clearfix" key={i}>
//             {/* ***** OJO:  ResuableLink is a customized component created by me, located in the globals.jsx file, below Apps.js ****** */}
//             { Object.keys(x).map(key => {return( <ReusableLink to={x[key][0]} category={key} img={x[key][1]} key={key} currentLang={y} /> )} ) }
//         </div>                    
//     )
// }


export const PortfolioMenu = (props) => {   
    // console.log(props);
    // console.log(props.currentLang);

    return ( 
        <div id="portfolioMenu" className="animEntrance">
            {/* { projects.map((x,i) => createContent(x,i,props.currentLang)  )}   */}

            { projects2.map((x,i) => createContent2(x,i,props.currentLang) )  }
        </div>  
    );
}


class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount(){ 
    } 

    render(props) {   
        return ( 
            <>
                <Route path="/res" component={(props) => <ResMenu {...props} currentLang={this.props.currentLang} />} />
                <Route path="/com" component={(props) => <ComMenu {...props} currentLang={this.props.currentLang} />} />
                <Route path="/edu" component={(props) => <EduMenu {...props} currentLang={this.props.currentLang} />} />
                <Route path="/sci" component={(props) => <SciMenu {...props} currentLang={this.props.currentLang} />} />
                <Route path="/tou" component={(props) => <TouMenu {...props} currentLang={this.props.currentLang} />} />
                <Route path="/urb" component={(props) => <UrbMenu {...props} currentLang={this.props.currentLang} />} />
                <Route path="/int" component={(props) => <IntMenu {...props} currentLang={this.props.currentLang} />} />

                <Residential currentLang={this.props.currentLang} />
                <Commercial  currentLang={this.props.currentLang} />
                <Scientific  currentLang={this.props.currentLang} />
                <Educational currentLang={this.props.currentLang} />
                <Tourism     currentLang={this.props.currentLang} />
                <Urbanism    currentLang={this.props.currentLang} />
                <Interiors   currentLang={this.props.currentLang} />
            </>                
        );
    }
}
 
export default Portfolio;
