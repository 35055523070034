// import React, { Component, useState, useEffect } from 'react';
import React, { Component } from 'react';
import "./studio.css";


const Common1 = (e)=> {
    if(Object.values(e)[0] === 1){
        return(
            <React.Fragment>
                Our craft<br/>is to <strong>Live</strong><br/>our clients' <strong>Dream</strong>,<br/>our gift is<br/>to make it tangible<br/>so they can <strong>Pursue it</strong>
            </React.Fragment>
        );
    }else{
        return(
            <React.Fragment>
                Nosso ofício<br/>é <strong>Viver</strong><br/>o <strong>Sonho</strong> de nossos clientes,<br/>nosso presente é<br/>torná-lo tangível<br/>para que eles <strong>possam Persegui-lo.</strong>
            </React.Fragment>
        );        
    }
}
const Common2 = (e)=> {
    const yearsOfExperience = (new Date().getFullYear() - 2001);
    if(Object.values(e)[0] === 1){
        return(
            <React.Fragment>
                <br/><br/><span>Amador Arquitectos</span><br/><span>The Studio</span><br/><br/>With the Founder and Creative Director Jorge Amador firmly at the wheel, the team drives headfirst into complex architectural tasks armed with unconditional curiosity and an undying love for the process of finding an original take.<br/><br/>With almost  
                &nbsp;{ yearsOfExperience } &nbsp;years of experience, we are always striving to perfect the art of listening and understanding the bigger picture. Our methodology is always in spired by the context and combines a blend of common sense and  place-making strategies that produces designs which are sustainable and that fully engage their communities. 
            </React.Fragment>
        );
    }else{
        return(
            <React.Fragment>
                <br/><br/><span>Amador Arquitetos</span><br/><span>O estúdio</span><br/><br/>Com o Fundador e Diretor Criativo Jorge Amador ao volante, a equipa lança-se de cabeça a tarefas arquitetónicas complexas munida com uma curiosidade incondicional e um amor eterno pelo processo de encontrar uma conquista original.
                <br/><br/>Com quase  
                &nbsp;{ yearsOfExperience } &nbsp;anos de experiência, estamos sempre nos esforçando para aperfeiçoar a arte de ouvir e entender o panorama geral. A nossa metodologia é sempre inspirada no contexto e combina uma mistura de bom senso e estratégias de place-making que produz designs sustentáveis ​​e que envolvem plenamente as suas comunidades. 
            </React.Fragment>
        );
    }
}

const Common3 = (e)=> {  
    if(Object.values(e)[0] === 1){
        return(
            <React.Fragment>
                <span>Jorge Amador, Architect, CNU-A, AIA, APA</span><br/><br/> Founder and CEO of <strong>Amador Arquitectos</strong>, established the Studio in Panama City (2014), after being a founding partner of <em>Amador + Valderrama Arquitectos</em> (2010) and Junior Associate in <em>Orfila &amp; Orfila arquitectos</em> (2005). He has carried out projects such as Cubitá (Hotel, Plaza and Residential), obtaining the <strong>National Prize of Architecture</strong> (2014), and has designed important banking, scientific, tourism and urban development venues.<br/>Currently, active member of the CONGRESS FOR THE NEW URBANISM  (CNU - Wash.DC., USA) as an accredited member CNU-A, the AMERICAN PLANNING ASSOCIATION (APA) and of the AMERICAN INSTITUTE OF ARCHITECTS (AIA - USA) as International Associate. In addition,       participates actively in specialties of the Graduate School of Design of the University of Harvard, in the areas of urbanism and design. <br/> <br/><strong>Education</strong><br/> 
                <table>
                    <tbody>
                        <tr><td>2001&nbsp;</td><td>USMA</td><td>Bachelor of Structural Architecture</td></tr>
                        <tr><td className="aaa_tdVTop">2017&nbsp;</td><td className="aaa_tdVTop">Harvard GSD&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td>Executive Program (Urban Retail)<br/>Executive Program (The Resilient City)</td></tr>            
                        <tr><td></td><td className="aaa_tdVTop">Miami Univ.&nbsp;&nbsp;&nbsp;&nbsp;</td><td>Principles and Practices  of the New urbanism</td></tr>
                    </tbody>
                </table> 
                <br/> <strong>Memberships</strong><br/> Congress For The New Urbanism (CNU)<br/>American Institute of Architects (AIA)<br/> American Planning Association (APA)<br/><br/> <strong>Awards and Honours</strong><br/> National Prize of Architecture (2014) - Cubita Hotel <br/>
                National Prize of Architecture (2021) - San Lucas Academy<br/>
            </React.Fragment>
        );
    }else{
        return(
            <React.Fragment>
                <span>Jorge Amador, Arquiteto, CNU-A, AIA, APA</span><br/><br/> Fundador e CEO da <strong>Amador Arquitectos</strong>, estabeleceu o Studio na Cidade do Panamá (2014), depois de ter sido sócio fundador da <em>Amador + Valderrama Arquitetos</em> (2010)e Associado Júnior na <em>Orfila &amp; Orfila arquitetos</em> (2005). Realizou projetos como o Cubita (Hotel, Plaza e Residencial), obtendo o  <strong>Prêmio Nacional de Arquitetura</strong> (2014), e projetou importantes espaços bancários, científicos, turísticos e de desenvolvimento urbano.<br/>Atualmente, membro ativo do CONGRESS FOR THE NEW URBANISM (CNU - Wash.DC., USA) como membro credenciado CNU-A, da AMERICAN PLANNING ASSOCIATION (APA) e do AMERICAN INSTITUTE OF ARCHITECTS (AIA - USA) como International Associado. Além disso, participa ativamente de especialidades da Graduate School of Design da Universidade de Harvard, nas áreas de urbanismo e design. <br/> <br/>
                <strong>Educação</strong><br/> 
                <table>
                    <tbody>
                        <tr><td>2001&nbsp;</td><td>USMA</td><td>Bacharel em Arquitetura Estrutural</td></tr>
                        <tr><td className="aaa_tdVTop">2017&nbsp;</td><td className="aaa_tdVTop">Harvard GSD&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td>Executive Program (Varejo Urbano)<br/>Executive Program (A Cidade Resiliente)</td></tr>            
                        <tr><td></td><td className="aaa_tdVTop">Miami Univ.&nbsp;&nbsp;&nbsp;&nbsp;</td><td>Princípios e Práticas do Novo Urbanismo</td></tr>
                    </tbody>
                </table> 
                <br/> 
                <strong>Associações</strong><br/> 
                Congresso para o Novo Urbanismo (CNU)<br/>Instituto Americano de Arquitetos (AIA)<br/>Associação Americana de Planeamento (APA)<br/><br/> 
                <strong>Prémios e honras</strong><br/>Prémio Nacional de Arquitetura (2014) - Hotel Cubita <br/>
                Prêmio Nacional de Arquitetura (2021) - San Lucas Academy<br/>
            </React.Fragment>
        );        
    }
}

// eslint-disable-next-line
const Common3x = (e)=> {  //console.log('x = '+Object.values(e)[0]);
    if(Object.values(e)[0] === 1){
        return(
            <React.Fragment>
                <span>Natércia Freitas, Architect</span><br/><br/>
                Architect and photographer. Graduated in architecture from Universidad José María Vargas in Caracas, Venezuela (2003) and freelance photographer from since 2006 until present. Advisor, development coordinator, management and supervision of residential, tourist, commercial and educational projects in accordance with the licenses required by the state for approval and construction, in architecture offices and construction companies in Venezuela, Panama and Portugal.Currently, an active member of Amador arquitectos as principal associate in Portugal.
                <br/> <br/><strong>Education</strong><br/> 
                <ul>
                    <li>2003 Bachelor of Architecture UJMV</li>
                    <li>'CIUDAD ELEA BIENAL LATINOAMERICANA' National Award for Participating Students III “Carlos Raúl Villanueva”. Featured Project 2003</li>
                    <li>'Paisajes Activados' Workshop Arq. Horacio Torrent Chilean Architecture Seminar UCV, FAU / 2005</li>
                    <li>XXIX CONGRESO COLOMBIANO DE ARQUITETURA Territorial, environmental and cultural sustainability of cities - Colombian Society of Architects 2005</li>
                    <li>ARQUITECTURA -- CIUDAD International Congress of Architectural Dystopia -- City Laboratory 2010</li>
                    <li>INTERIOR DESIGN CERTIFICATION 2022-2023</li>
                </ul>
                <br/> <strong>Photography</strong><br/>
                <ul>
                    <li>IV BIENNIAL EDITION OF PHOTOGRAPHY EL HATILLO / VII SEGUROS CATATUMBO PHOTOGRAPHY SALON / LOVERA ART SALON 2007 – 2010</li>
                    <li>FUNDACION ESPACIO Photograph of Professional Development Courses - International Architecture Congresses - Cover of the book PROYECTO IDEA OBRA 2009-2011</li>
                    <li>ARCHITECTURE AND HERITAGE PHOTOGRAPHY Arq. Antonio Castañeda (Colombia) RMTF 2010</li>
                </ul>
                
            </React.Fragment>
        );
    }else{
        return(
            <React.Fragment>
                <span>Natércia Freitas, Arquiteta</span><br/><br/>
                Arquiteta e fotógrafa. Formada em arquitetura pela Universidade José María Vargas em Caracas, Venezuela (2003) e fotógrafa freelancer de 2006 até ao presente. Assessora, coordenadora de desenvolvimento, gestão e supervisão de projetos residenciais, turísticos, comerciais e educacionais de acordo com as licenças exigidas pelo estado para aprovação e construção, em escritórios de arquitetura e empresas de construção na Venezuela, Panamá e Portugal.
                Atualmente, é membro ativo da Amador Arquitectos como associado principal em Portugal.
                <br/> <br/><strong>Educação</strong><br/> 
                <ul>
                    <li>2003 UJMV Licenciatura em Arquitetura </li>
                    <li>CIUDAD ELEA BIENAL LATINOAMERICANA. Prêmio Nacional de Alunos Participantes III "Carlos Raúl Villanueva". Projeto em destaque 2003</li>
                    <li>Workshop de paisagens ativada Arq. Horacio Torrent Seminário de Arquitetura Chilena UCV, FAU / 2005</li>
                    <li>XXIX CONGRESO COLOMBIANO DE ARQUITETURA Sustentabilidade territorial, ambiental e cultural das cidades - Sociedade Colombiana de Arquitetos 2005</li>
                    <li>ARQUITECTURA – CIUDAD Congreso Internacional de Arquitetura Distopia– Laboratorio da Ciudade 2010</li>
                    <li>CERTIFICAÇÃO DE DESIGN DE INTERIORES 2022-2023</li>
                </ul>
                <br/> <strong>Fotografía</strong><br/>
                <ul>
                    <li>IV EDIÇÃO BIENAL DE FOTOGRAFIA EL HATILLO / VII SALÃO DE FOTOGRAFIA SEGUROS CATATUMBO / SALÃO DE ARTE LOVERA 2007 – 2010</li>
                    <li>FUNDACION ESPACIO Fotografia de Cursos de Desenvolvimento Profissional - Congressos Internacionais de Arquitetura - Capa do livro PROYECTO IDEA OBRA 2009-2011</li>
                    <li>FOTOGRAFIA DE ARQUITETURA E PATRIMÔNIO Arq. Antonio Castañeda (Colômbia) RMTF</li>
                </ul>
                
            </React.Fragment>
        );
    }  
}



const Row1 = (e) => {  
    let lang = Object.values(e)[0];
    return (
        <React.Fragment>
            <div className="rowContainer">
                <img id="aaa_ceoProfileImg1" src="../img/mozaico/aaa_ceoprofile2.jpg" width="1170" height="780" alt="" className="responsive" />
                <div id="aaa_ceoProfile3" className="T400">
                    <div id="aaa_ceoProfile3_1">
                        <div id="aaa_ceoProfile3_2"><Common1 e={lang} /></div>
                        <Common2 e={lang} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const Row2 = (e) => {
    let lang = Object.values(e)[0];
    return (
        <React.Fragment>
            <div id="aaa_ceoProfile4">
                <div id="aaa_ceoProfile4_1">
                    <div id="aaa_ceoProfile4_2"><Common1 e={lang} /></div>
                    <Common2 e={lang} />
                </div>
            </div>
        </React.Fragment>
    );
}

const Row3 = (e) => {
    return(
        <React.Fragment>
            {/* <div className="rowContainer clearfix">
                <img id="aaa_ceoProfileImg2" src="../img/mozaico/aaa_ceoprofile3.jpg" width="1170" height="780" alt="" className="responsive" />
            </div> */}
            {/* <div className="rowContainer clearfix">
                <img id="aaa_ceoProfileImg3" src="../img/mozaico/aaa_ceoprofile4.jpg" width="1170" height="780" alt="" className="responsive" />
            </div> */}
            <br/><br/>
        </React.Fragment>
    );
}

const Row4 = (e) => { //console.log(Object.values(e)[0]);
    let lang = Object.values(e)[0];
    return(
        <React.Fragment>
            <div className="rowContainer">
                <img id="aaa_ceoProfileImg0" src="../img/mozaico/aaa_ceoprofile.jpg" width="1170" height="780" alt="" className="responsive" />
                <div id="aaa_ceoProfile" className="T400 gradient1"><br/><br/><Common3  e={lang} /></div>
                <div id="aaa_ceoProfile2" className="T800"><br/><Common3  e={lang} /></div>
                <br /><br /><br /><br />
            </div>
            {/* <div className="rowContainer">    
                <img id="aaa_ceoProfileImg1" src="../img/mozaico/aaa_nattie.jpg" width="1170" height="780" alt="" className="responsive" />
                <div id="aaa_ceoProfilex" className="T400"><br/><br/><Common3x e={lang} /></div>
                <div id="aaa_ceoProfilex2" className="T400 gradient1"><br/><Common3x e={lang} /></div>
            </div> */}
        </React.Fragment>

    );
}



class Studio extends Component {
    state = {  }
    componentDidMount(){
        // document.getElementById('aaaMiddle').style.maxWidth = "1200px";
    }
    render(props) {  //console.log('xavier = '+this.props.currentLang);
        return ( 
            <div id="aaa_studioContainer" className="T400  animEntrance">
                <br/><br/><br/><br/><br/><br/>
                <Row1  e={this.props.currentLang} />
                <Row2  e={this.props.currentLang} />
                <br/><br/>
                <Row3  e={this.props.currentLang} />
                <Row4 e={this.props.currentLang} />
                {/* <Row5 /> */}
                {/* <Row6 />
                <br/> */}
                {/* <Row7 /> */}
            </div>
        );
    }
}
 
export default Studio;