// import React, { useState, useEffect } from 'react';
// import React from 'react';

// ********  Xavier[14/Aug/2022]:  howTo pass props between components https://www.youtube.com/watch?v=Qf68sssXPtM   **********
import React, { useState } from 'react';

// import { useContext, __RouterContext } from 'react';
import { Route, Switch,  withRouter  } from "react-router-dom";
// import { useTransition, animated } from 'react-spring'; 

import Header from "./comps/header/header";
import Footer, { ShareSocNet } from "./comps/footer/footer";
import Home from "./comps/home/home";
// import SliderHome from "./comps/home/slider";  // COMMENT THIS FOR SLIDER IN INDEX.HTML 

import Portfolio, { PortfolioMenu } from "./comps/portfolio/portfolio";
import Studio from "./comps/studio/studio";
import Careers from "./comps/careers/careers";
// import Contact from "./comps/contact/contact";
import Email from "./comps/contact/email";
import GMap from "./comps/contact/googlemap";
import Journal from "./comps/journal/journal";
import Subscribe from "./comps/journal/subscribe";
import Publications, { PubMagazines } from "./comps/pubs/pubs";
import Volunteer from "./comps/volunteer/volunteer";
import Videos from "./comps/videos/videos";
import Oficinas from "./comps/Oficinas/Oficinas";
// import { m } from "./globals";
import Browser from './comps/browser/browser';
import './App.css';

function App() {
//   const { location } = useContext(React.createContext);
//   const transitions = useTransition(location, location => location.pathname, {
//       from: { opacity: 0, transform: "translate(0, 10px)"  },
//       enter: { opacity: 1, transform: "translate(0, 0)"  },
//       leave: { display: "none"}
//   }); 

//   const [aaaurl, setAaaurl] = useState({url: window.location.pathname });

  // https://medium.com/javascript-in-plain-english/react-hooks-how-to-use-useeffect-ecea3e90d84f
  // https://reactjs.org/docs/hooks-effect.html
//   useEffect(() => { 
//         setAaaurl({ url: window.location.pathname }); 
//         const aaamiddle = document.getElementById('aaaMiddle');
//         if( aaaurl.url !== "/"){
//             aaamiddle.style.paddingTop = "80px";
//             aaamiddle.style.width = "80%";
//             aaamiddle.style.height = "auto";
//             if(aaaurl.url === "/journal"){
//                 aaamiddle.style.maxWidth = "1500px";
//             }else{
//                 aaamiddle.style.maxWidth = "1160px";
//             }        
//         }
//   },[aaaurl.url]);
  
  let [currentLang, setCurrentLang] = useState(1);


  return (
    // <div id="mainContainer" name="mainContainer" className="exanet_absT0L0W100H100M0P0 exanet_Scroller" onScroll={xxx}>
    <div id="mainContainer" name="mainContainer" className="exanet_absT0L0W100H100M0P0 exanet_Scroller">
        <div id="x" />
        <div id="aaa_scrollUp" onClick={xxx} />
        {/* HEADER=MENU ALWAYS MUST BE INSIDE BrowserROUTER  to WORK */}
        <Header setCurrentLang={setCurrentLang} />
        <div id="aaaMiddle" className="clearfix" >
            {/* {transitions.map(({ item, props, key }) => (
                <animated.div key={key} style={props}> */}
                    {/* <Switch location={item}> */}
                    <Switch>
                        {/* <Route path="/"          component={Home} exact strict /> */}
                        <Route path="/"          component={(routeProps) => <Home {...routeProps} currentLang={currentLang} />}  exact strict />


                        {/* <Route path="/portfolio" component={PortfolioMenu} /> */}
                        {/* <Route path="/portfolio"    component={(routeProps) => <PortfolioMenu {...routeProps} currentLang={currentLang} />} /> */}
                        <Route path="/portfolio"    render={(routeProps) => <PortfolioMenu {...routeProps} currentLang={currentLang} />} />


                        {/* <Route path="/studio"    component={Studio} /> */}
                        <Route path="/studio"    component={(routeProps) => <Studio {...routeProps} currentLang={currentLang} />} />


                        {/* <Route path="/careers"   component={Careers} /> */}
                        <Route path="/careers"   component={(routeProps) => <Careers {...routeProps} currentLang={currentLang} />} />
                        {/* <Route path="/contact"   component={Contact} /> */}
                        {/* <Route path="/journal"   component={Journal} /> */}
                        <Route path="/journal"   component={(routeProps) => <Journal {...routeProps} currentLang={currentLang} />} />
                        
                        {/* <Route path="/pubs"      component={Publications} /> */}
                        <Route path="/pubs"   component={(routeProps) => <Publications {...routeProps} currentLang={currentLang} />} />
                        
                        {/* <Route path="/volunteer" component={Volunteer} /> */}
                        <Route path="/volunteer"   component={(routeProps) => <Volunteer {...routeProps} currentLang={currentLang} />} />

                        <Route path="/oficinas"   component={(routeProps) => <Oficinas {...routeProps} currentLang={currentLang} />} />

                        <Portfolio currentLang={currentLang} />
                    </Switch>
                {/* </animated.div> 
            ))}  */}
        </div>
        {/* <SliderHome />  COMMENT THIS FOR SLIDER IN INDEX.HTML */}
        <br/><br/>
        <Footer currentLang={currentLang} />
        <br/><br/><br/>
        <GMap />
        <Subscribe />
        <PubMagazines />
        <ShareSocNet />
        <Videos />
        <Email />
        <Browser />
     </div>
);
}

const xxx = () => {
  // https://css-tricks.com/snippets/jquery/smooth-scrolling/
  document.querySelector('#x').scrollIntoView({ behavior: 'smooth' });
}


// export default App;
export default withRouter(App);
