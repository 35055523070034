import React, {Component} from "react";
import "./subscribe.css";

class  Subscribe extends Component {
    state = { 
        subs: ""
     }
    subscribeText = (x) => {
        let subs = [
            ["Servicios de Subscripci&oacute;n", "Subscription Services"],
            [` <table class='aaa_fix1'><tbody><tr><td colspan='2'><strong>Newsletters</strong><br><br></td></tr>
                <tr><td style='vertical-align: top; '><div id='divSprite1'><img class='subscribeimg' src='images/misc/aaa_sprite1.png'/></div>
                Reciba en su email nuestra Newsletter con informaci&oacute;n valiosa sobre eventos sociales  
                en Panam&aacute; referente a Arquitectura, 
                importantes proyectos urbanisticos y comerciales, actualizaciones sobre dise&ntilde;os tur&iacute;sticos y los &uacute;ltimos 
                desarrollos residenciales en nuestro pa&iacute;s.</td></tr></tbody></table>`,
                `<table class='aaa_fix1'><tbody><tr><td colspan='2'><strong>Newsletters</strong><br><br></td></tr>
                <tr><td style='vertical-align: top; '><div id='divSprite1'><img class='subscribeimg' src='img/misc/aaa_sprite1.png'/></div>
                Get our Newsletters with valuable information on social events concerning Architecture,  
                important urban and commercial projects, updates on touristic designs and the latest residential developments.
                </td></tr></tbody></table>`],
            [`<em style='color: #ff5100'> 'Prefiero dibujar en lugar de hablar. Dibujar es mas r&aacute;pido, y deja menos espacio para
                mentiras.'</em> <br/>  <span style='font-size: 18px; color:#555;'>Le Corbusier</span>`,
                `<em style='color: #ff5100'> 'I prefer drawing to talking. Drawing is faster, and leaves less room for lies'</em>
                <br/>  <span style='font-size: 13px; color:#777;'>Le Corbusier</span>`],	
        ];
        switch(x){
            case 0: return {__html: subs[2][1]}; 
            case 1: return {__html: subs[0][1]}; 
            case 2: return {__html: subs[1][1]}; 
            default: return null;
        }
    }

    componentDidMount(){
        this.setState({subs:  document.getElementById('aaa_subscribeNL')});
    }

    closeSubscribe = () => {
        this.state.subs.classList.add('zoomOut');
        window.setTimeout(()=>{this.state.subs.classList.add('notVisible','cssHide');}, 400);
        // this.state.subs.classList.add('notVisible','cssHide');
    }
    
    render() { 
        return ( 
            <div id="aaa_subscribeNL" className="cssHide notVisible T300 zoomOut">
                <div id='aaaModule_SubscribeScrollContainer' className="aaaScroller">
                    <div id="_aaaContainer_StudioSubscribe">
                        <div id="bgSubscribe"></div>
                        <div id="subscribe1" className=''>
                            <div id="citation5" dangerouslySetInnerHTML={this.subscribeText(0)} ></div><br /><br />
                            <div className="subscribe1title" dangerouslySetInnerHTML={this.subscribeText(1)}></div>
                            <div id="subscribe2" dangerouslySetInnerHTML={this.subscribeText(2)}></div>

                            <div id="mc_embed_signup">
                                <form action="//amadorarquitectos.us10.list-manage.com/subscribe/post?u=797a0c653c912a653d47b51da&amp;id=670c4020c6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate >
                                    <div id="mc_embed_signup_scroll">
                                        <table><tbody>
                                            <tr className="h32">
                                                <td>
                                                    <div className="mc-field-group">
                                                        <input autoFocus type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="e-Mail" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="h32">
                                                <td className=''>
                                                    {/* <input id="aaa_subsInput1" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button aaa_floatNone" /> */}
                                                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button aaa_floatNone" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div id="mce-responses" className="clear">
                                                        <div id="mce-error-response" className="aaa_hideError response"></div>
                                                        <div id="mce-success-response" className="aaa_hideError response"></div>
                                                    </div>
                                                    <div className="aaa_hideInput"><input type="text" name="b_797a0c653c912a653d47b51da_670c4020c6" tabIndex="-1"  /></div>
                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </div>
                                </form>
                            </div>
                        </div> 
                    </div> 
                    <div id="aaa_subscribeClose" onClick={this.closeSubscribe}></div>
                </div>
            </div>
        );
    }
}
 


export default Subscribe;