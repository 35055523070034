import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import "./pubs.css";

const ReusableLink6 = (props) => {
    return(
        <div className="imgContainer">
            <figure>
                <img alt={props.category} src={"../img/" + props.img} className="responsive" onClick={() => showMag(props.to)} />
                <figcaption className="figureTitle">{props.category}</figcaption>
            </figure>
        </div>
    )
}

const proj1 = {
    "ABC Design Magazine": ['/abc','misc/aaa_abc.jpg'],
    "Area Magazine":       ['/area','misc/aaa_area.jpg'],
}

const PubMenu = () => {
    return ( 
        <div>
            <div className="row clearfix">
                { Object.keys(proj1).map(key => {return( <ReusableLink6 to={proj1[key][0]} category={key} img={proj1[key][1]} key={key} /> )})}
            </div>                
        </div>  
    );
}


const showMag = (e) => { 
    let aux = "";
    let landscape = window.innerWidth > window.innerHeight ? true : false;
    switch(e){
        case '/abc': aux = 'abcmag.html'; break;
        case '/area': aux = 'area26mag.html'; break;
        default: break;
    }
    if((window.innerWidth < 769 && !landscape) || (window.innerWidth < 1025 && landscape) ){
        console.log('x');
        window.open("https://amadorarquitectos.com/"+aux);
    }else{
        let pubFrame = document.getElementById('aaa_pubFrame');
        document.getElementById('magFrame').setAttribute('src',aux);
        pubFrame.classList.remove('cssHide');
        pubFrame.classList.remove('notVisible');
    }
}

export const PubMagazines = () => {  
    return (
        <div id="aaa_pubFrame" className="T600 exanetContainer cssHide notVisible" >
            <iframe id="magFrame" src="" frameBorder="0" title="magFrame"/>
        </div>
    );
}

class Publications extends Component {
    state = {  }
    componentDidMount(){ }
    render() { 
        return ( 
            <div id="aaa_PublicationsContainer" className="T600  animEntrance">
                <div id="aaa_pub" className="T600 clearfix">
                    <BrowserRouter>
                        <Switch>
                            <Route path="/pubs" component={PubMenu} />
                        </Switch>
                    </BrowserRouter>                        
                </div>
            </div>


        );
    }
}
 
export default Publications;