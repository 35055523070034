import React, { Component } from 'react';
import "./footer.css";


export const ShareSocNet = (params) => {
    const sharethis = ["Share our Services", "with your Clients"];
    return (
        <div id="aaaModule_Shares" className="aaa_preserve3D aaaTransOrigCenter centered2 cssHide">
            <div id="zoomer" className="exanet_relT0L0W100H100M0P0 aaa_shares aaa_preserve3D aaaTransOrigCenter T300 aaaModule_ShareFirstDiv zoomOut aaaBGCover">
                <div className="sharethis"><br/><span>{sharethis[0]}</span><br/><span>{sharethis[1]}</span></div>
                <br/>
                <a href="//gb.pinterest.com/pin/create/button/?url=http%3A%2F%2Famadorarquitectos.com%2F&media=http%3A%2F%2Famadorarquitectos.com%2Fimages%2Fmisc%2Flogo4facebook.jpg&description=Amador%20+%20Architects%20Associated" data-pin-do="buttonPin" data-pin-config="beside" data-pin-color="white" ><div id="pinit_pic"></div></a>
                <br/>
                <div id="fb-like" className="fb-like" data-href="http://amadorarquitectos.com/" data-layout="button_count" data-action="recommend" data-show-faces="true" data-share="true"></div>
                <br/><br/>
                <div id="aaaModule_SocialNets2">
                    <div className="aaa_networks">
                        <div className="net facebook   aaaT400"></div>
                        <div className="net pinterest  aaaT400"></div>
                        <div className="net instagram  aaaT400"></div>
                        <div className="net youtube    aaaT400"></div>
                        <div className="net linkedin   aaaT400"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};


class Footer extends Component {
    constructor(props) {
        super(props);
        this.shareModule = null;
        this.shareZommer = null;
        this.setShareModule = e => {
            this.shareModule = e;
            this.shareZommer = document.getElementById('zoomer');
        };
        this.state = {
            active: true,
            email: ''
        };  
    }
    
    componentDidMount(){
        this.setShareModule(document.getElementById('aaaModule_Shares'));

         // email
         this.setState({ email: document.getElementById('aaa_emailContainer') });

    }
    

    socNet = (e) => {
        let aux = "";
        switch(e.currentTarget.id){
            case "aaa_facebook": aux = "https://www.facebook.com/amadorarquitectos"; break;
            case "aaa_linkedin": aux = "https://www.linkedin.com/company/amador-arquitectos-asociados"; break;
            case "aaa_youtube": aux = "https://www.youtube.com/channel/UCWmTI2mw87VclL77a80dflw"; break;
            case "aaa_instagram": aux = "https://instagram.com/amador.arquitectos/"; break;
            default: break;
        }
        window.open(aux);
    }

    share = () => {
        if( this.shareModule.classList.contains('cssHide') ){
            this.shareModule.classList.remove('cssHide');
            window.setTimeout(()=>{ this.shareZommer.classList.remove('zoomOut'); },300);
        }else{
            this.shareZommer.classList.add('zoomOut');
            window.setTimeout(()=>{ this.shareModule.classList.add('cssHide'); },600);
        }
    }
    
    showHide = (props) => {
        // console.log(props);
        // console.log(props.currentTarget.id);
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }
  
    showEmailOverlay = () => {
        this.state.email.classList.remove("cssHide");
        setTimeout(()=>{ this.state.email.classList.remove("hideemailoverlay"); },300);
        // document.querySelector('#mainContainer').scrollTop = 0;
        document.querySelector('#x').scrollIntoView({ behavior: 'smooth' });
    }

    myDisclaimer = {
        'eng': ['Projects CACAO, CUBITA HOTEL, CASAS, PLAZA, EDEN, EPK, IGRA, LMCH, MAR DEL SUR, COSMOPOLITAN, PRIME, PAMPOULIE, PRIVAL, STRI NAOS, shown is this web site were executed by the former old Firm Amador + Valderrama Arquitectos S.A., which no longer exist.'],
        'pt': ['Projetos Cacao, Cubita Hotel, Casas, Plaza, Eden, Epk, Igra, LMCH, Mar del Sur, Cosmopolitan, Prime, Pampoulie, Prival, Stri Naos, mostrados é que este site foi executado pela antiga empresa antiga Amador + Valderrama Arquitectos S.A. , que não existem mais.']
    }

    render(props) { 
        return ( 
            <div id="aaaFooter" className="T400 clearfix animEntrance">
                <div className="footerContainer">
                    <div className="swiper-footer globalContainer">
                        {/* <div>
                            Panama City<br/>
                            Santa Maria Business District<br/>Prime 507<br/> Suite 604<br/>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Amador+%2B+Arquitectos/@9.0284971,-79.4631055,17z/data=!3m1!4b1!4m5!3m4!1s0x8faca906e0756f79:0x20f4ddcf6ace5a21!8m2!3d9.0284971!4d-79.4609115">Google Map</a><br/>
                            507.226.61.44 &nbsp; | &nbsp; 507.226.14.11 <br/>
                            <em className="sendemail" onClick={ this.showEmailOverlay }>Send us an e-mail</em>  
                        </div> */}
                    </div>
                    <div id="aaa_socnetIcons1" className="aaa_socnetIcons clearfix">
                        <div id="aaa_share" className="T200 aaa_socnet" onClick={this.share}></div>
                        <div id="aaa_facebook" className="T200 aaa_socnet" onClick={this.socNet}></div>
                        <div id="aaa_linkedin" className="T200 aaa_socnet" onClick={this.socNet}></div>
                        <div id="aaa_youtube" className="T200 aaa_socnet" onClick={this.socNet}></div>
                        <div id="aaa_instagram" className="T200 aaa_socnet" onClick={this.socNet}></div>
                    </div>
                    <br/>
                </div>
                <div id="aaa_disclaimer" className="clearfix" >
                    2010 CopyRighted <span>©</span>  All Rights Reserved <span>® </span> <br/><br/><br/>
                    <div className={this.state.active ? 'aaa_hideDisclaimer T200': 'aaa_showDisclaimer T200' } onClick={this.showHide}><strong style={{ fontSize: "13px" }}>{this.props.currentLang === 1? 'Disclaimer':'Isenção de Responsabilidade'}</strong><br/><br/><em>{this.props.currentLang === 1? this.myDisclaimer.eng[0]:this.myDisclaimer.pt[0]}</em></div>
                </div>
            </div>
        );
    }
}


export default Footer;