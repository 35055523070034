import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ReusableLink2 } from "../../../globals";
import Project from "../project";


const proj1 = {
    "Punta Culebra Visitors Center": ['/culebra','aaa_portfolioSciPic2.jpg'],
    "Punta Galeta": ['/galeta','aaa_portfolioSciPic3.jpg'],
}

const sciProjects = ["culebra", "galeta"];



export const SciMenu = () => {
    return (
        <div className="exanetAnim1">
            <div className="categoryTitle goggleOpenSans">Scientific</div>
            <div className="row clearfix">
                { Object.keys(proj1).map(key => {return( <ReusableLink2 to={proj1[key][0]} category={key} img={proj1[key][1]} key={key} /> )})}
            </div>                
        </div>
    );
}



class Scientific extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    render(props) { 
        return ( 
            <>
                {/* Xavier[15/Ago/2022]:  aqui se llama a  'project.jsx'  que esta en el directorio  comps/portfolio    */}
                { sciProjects.map(x => { return(<Route path={"/"+x} key={x} render={(props)=> <Project {...props} projectCat="sci/" projectName={x} currentLang={this.props.currentLang}  />} /> )}) }
            </>
        );
    }
}
 
export default Scientific;