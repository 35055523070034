import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ReusableLink2 } from "../../../globals";
import Project from "../project";

const projects = [
    {
    "Eden":                  ['/eden','aaa_portfolioUrbPic5.jpg'],
    "Santa Monica":          ['/santamonica','aaa_portfolioUrbPic2.jpg'],
    "Primavera Residential": ['/primavera','aaa_portfolioUrbPic3.jpg'],
    },
    {
    "Panama Pacifico":       ['/panpacific','aaa_portfolioUrbPic4.jpg'],
    "Cubita":                ['/cubitaurb','aaa_portfolioUrbPic6.jpg'],
    "Woodland":              ['/woodland','aaa_portfolioUrbPic7.jpg'],
    },
]


export const UrbMenu = (props) => {
    // getProjects("../../../json/urb/urb.json");

    return (
        <div className="exanetAnim1">
            <div className="categoryTitle goggleOpenSans">Urbanism</div>
            { projects.map((x,i) => {
                return(
                    <div className="row clearfix" key={i}>
                        { Object.keys(x).map(key => {return( <ReusableLink2 to={x[key][0]} category={key} img={x[key][1]} key={key} currentLang={props.currentLang} /> )} ) }
                    </div>                    
                )

            }) } 
        </div>
    );
}

class Urbanism extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render(props) { 
        return ( 
            <>
                { projects.map(x => Object.keys(x).map((key,i) => { return( <Route path={x[key][0]} key={i} render={(props) => (<Project {...props} projectCat="urb/" projectName={x[key][0].replace('/','')} />)} /> ) } ) ) }
            </>
        );
    }
}




export default Urbanism;