import React, { Component } from 'react';
import { Route, BrowserRouter, NavLink } from 'react-router-dom';
import "./journal.css";


const showSubscribe = () => {
    let subscribeOverlay = document.getElementById('aaa_subscribeNL');
    subscribeOverlay.classList.remove("cssHide");
    window.setTimeout(()=>{subscribeOverlay.classList.remove("notVisible","zoomOut");}, 100);
}
const JournalHeader = () => {
    return(
        <React.Fragment>
            <div id="aaa_CCHeader" className="bgauto100 "></div><br/>
            <div className="borderLine"></div>
            <div id="aaa_ColTitles">
                <div className="container-fluid">
                    <table id="aaa_journalColCategories">
                        <tbody>
                            <tr>
                            <td className="c18">-DESIGN-</td>
                            <td className="c2_5"></td>
                            <td className="c18">-BUILD-</td>
                            <td className="c2_5"></td>
                            <td className="c18M">-PROJECTS-</td>
                            <td className="c2_5"></td>
                            <td className="c18">-A&amp;E-</td>
                            <td className="c2_5"></td>
                            <td className="c18">-EVENTS-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="borderLine1"></div>
            <div id="aaa_buttonSubscribe" onClick={showSubscribe}><strong className="font24">Subscribe</strong><br/>to our Newsletter</div>
        </React.Fragment>
    );
}
const JournalIndivColsMain = (props) => {
    return(
        <div id={props.id.replace("aaa_",'')} className="aaa_journalRowColIndiv aaa_journalColL">
            <div className="aaa_journalThemeContainer">
            <figure>
                <NavLink to="/article" className="noDecor" onClick={(e)=> props.changeState(props.id)} >
                {/* <NavLink to={props.to} className="noDecor"> */}
                    <img id={props.id} src={"../../img/journal/"+props.img} width="292px" height="175px" alt="" className="responsive" />
                </NavLink>
                <figcaption className="journalLegend"><div dangerouslySetInnerHTML={{ __html: props.legend} } /></figcaption><br/>
            </figure>
            </div>
            <div className="aaa_shadow"></div>
        </div>   
    );
}
const JournalIndivCols = (props) => {
    return(
        <div id={props.id.replace("aaa_",'')} className="aaa_journalRowColIndiv aaa_journalColL">
            <figure>
                <NavLink to="/article" className="noDecor" onClick={(e)=> props.changeState(props.id)}>
                {/* <NavLink to={props.to} className="noDecor"> */}
                    <img id={props.id} src={"../../img/journal/"+props.img} width="292px" height="175px" alt="" className="responsive" />
                </NavLink>
                <figcaption className="journalLegend"><div dangerouslySetInnerHTML={{ __html: props.legend}} /></figcaption><br/>
            </figure>
        </div>   
    );
}
const j5 = {
    "aaa_picNL5_1": ["designNL5", "design_nl5.jpg", "<strong>A</strong>romas que decoran y brindan bienestar a los espacios"],
    "aaa_picNL5_2": ["buildNL5", "build_nl5.jpg",  "<strong>H</strong>ortalizas en casa. Breve Guia para el cultivo en patio o jardin"],
    "aaa_picNL5_3": ["projNL5", "proj_nl5.jpg",   
                    "<div class='aaa_themetextTitle black'>Proyecto Destacado EDEN</div> El Paraiso a tu alcance."],
    "aaa_picNL5_4": ["aneNL5", "ane_nl5.jpg",    "<strong style='font-weight: bold'>Renzo Piano, Arq.</strong><br/>Breve Vida y Obra."],
    "aaa_picNL5_5": ["eventsNL5", "events_nl5.jpg", "<strong>M</strong>arket Plaza<br> Lugar recomendado para compartir en familia."],
}
const j4 = {
    "aaa_picNL4_1": ["designNL4", "design_nl4.jpg", "<strong>E</strong>l espacio de estudio ideal para los niños"],
    "aaa_picNL4_2": ["buildNL4", "build_nl4.jpg",  "<strong>G</strong>uía para maximizar el espacio de un closet"],
    "aaa_picNL4_3": ["projNL4", "proj_nl4.jpg",   
                    "<div class='aaa_themetextTitle black'>Proyecto Punta Culebra</div> Nuevo Centro para Visitantes"],
    "aaa_picNL4_4": ["aneNL4", "ane_nl4.jpg", "<strong style='font-weight: bold'>Rogelio Salmona, Arq.</strong><br/> <em>El Transformador de Ciudades</em>. Breve Vida y Obra."],
    "aaa_picNL4_5": ["eventsNL4", "events_nl4.jpg", 
                    "<strong>C</strong>ómo observar eventos Astrónomicos en Panamá ? Es más fácil de lo que se cree."],    
}
const j3 = {
    "aaa_picNL3_1": ["designNL3", "design_nl3.jpg", 
                    "<strong>C</strong>uáles son aquellos elementos que por su utilidad han marcado la Historia de la Humanidad? <br/>"],
    "aaa_picNL3_2": ["buildNL3", "build_nl3.jpg",  
                    "<strong>C</strong>uáles son las plantas que más se destacan a la hora de crear su jardín interior?"],
    "aaa_picNL3_3": ["projNL3", "proj_nl3.jpg",   
                    "<div class='aaa_themetextTitle black'>Academia Integral San Lucas</div> Hacer ciudad no es solamente planeamiento urbano; es caminar, oler, sentir el paisaje y dejar que <em>'Los ojos de la piel'</em> también puedan sentir."],
    "aaa_picNL3_4": ["aneNL3", "ane_nl3.jpg",    
                    "<strong>Q</strong>ué hace la gente exitosa justo antes de dormir? Qué rutinas los llevan a alcanzar la plenitud?"],
    "aaa_picNL3_5": ["eventsNL3", "events_nl3.jpg", 
                    "<strong>S</strong>algamos de la casa y participemos en varios eventos importantes: Paseo a Ciegas, Steak House, PAW PATROL, PRISMA"],    
}
const j2 = {
    "aaa_picNL2_1": ["designNL2", "design_nl2.jpg", 
                    "<div class='aaa_themetextTitle'>Decoración con niños en casa</div> Es posible tener una casa bella donde habitan los mas pequeños en tamaño, pero grandes en creatividad..."],
    "aaa_picNL2_2": ["buildNL2", "build_nl2.jpg",  
                    "<div class='aaa_themetextTitle'>Cómo debe ser la piscina ideal?</div> Fácil, aquella que realmente se acomode a las necesidades de sus usuarios...<br/>"],
    "aaa_picNL2_3": ["projNL2", "proj_nl2.jpg",   
                    "<div class='aaa_themetextTitle'>Greenhouse</div> Amor por lo verde, pasion por la buena vida...<br/>"],
    "aaa_picNL2_4": ["aneNL2", "ane_nl2.jpg",    
                    "<div class='aaa_themetextTitle'>Desarrollo Social &amp; Empresarial</div> Diversas actividades para todas las edades y preferencias... <br/>"],
    "aaa_picNL2_5": ["eventsNL2", "events_nl2.jpg", 
                    "<div class='aaa_themetextTitle'>Dónde tomar un rico café?</div> Un buen ambiente y un asombroso cafe, hacen especiales a estos cuatro lugares en Panamá...<br/>"],    
}
const j1 = {
    "aaa_picNL1_1": ["designNL1", "design_nl1.jpg", 
                    "<div class='aaa_themetextTitle'>Diseñando para la salud y una mejor calidad de vida</div> Son muchas las maneras por medio de las cuales la medicina mejora la vida y su aporte se vuelve mayor, cuando... <br/>"],
    "aaa_picNL1_2": ["buildNL1", "build_nl1.jpg",  
                    "<div class='aaa_themetextTitle'>Eficiencia energética y aislamiento térmico en los hogares</div> Tener en cuenta estos dos factores a la hora de construir , eliminaría perdidas de energía y evitaría ambientes mas fríos... <br/>"],
    "aaa_picNL1_3": ["", "proj_nl1.jpg",""],
    "aaa_picNL1_4": ["aneNL1", "ane_nl1.jpg",    
                    "<div class='aaa_themetextTitle'>FINLANDIA: Por que arrasa en educación?</div> El modelo educativo Finlandés se ha convertido en un referente para el mundo. Continua en el primer lugar de toda... <br/>"],
    "aaa_picNL1_5": ["eventsNL1", "events_nl1.jpg", 
                    "<div class='aaa_themetextTitle'>Invasion Musical: El Jazz y su cultura se toman la ciudad</div> Ubicado estratégicamente y rodeado de un ambiente ideal, en el Casco Antiguo se encuentra el <em>Danilo’s Jazz Club</em>,...<br/>"],    
}

const JournalMenu = (props) => {
    return(
        <div id="aaa_journalContainer" className="aaaT600 animEntrance">
            <div id="aaa_journalScroller">
                <JournalHeader />
            
                <div id="aaa_CCMainBody">
                    <div className="aaa_postTitle">
                        - In this issue -<br/><span className="tenue">(Spanish only - Click on the images to read the article)</span>
                    </div>

                    <div id="aaa_journalNL5" className="aaa_journalRowCol clearfix">
                        {Object.keys(j5).map(x => {return(<JournalIndivColsMain changeState={props.changeState} id={x} to={j5[x][0]} img={j5[x][1]} legend={j5[x][2]} key={x} />)})}
                    </div>
                    <br/><br/>
                    <div className="borderLine"></div>
                    <div id="aaa_archiveTitle">- Archive -</div>
                    <br/>

                    <div id="aaa_journalNL4" className="aaa_journalRowCol clearfix">
                        <div className="colDate">Jun 30, 2018</div>
                        {Object.keys(j4).map(x => {return(<JournalIndivCols changeState={props.changeState} id={x} to={j4[x][0]} img={j4[x][1]} legend={j4[x][2]} key={x} />)})}
                    </div>
                    <br/><br/>

                    <div id="aaa_journalNL3" className="aaa_journalRowCol clearfix">
                        <div className="colDate">Mar 15, 2018</div>
                        {Object.keys(j3).map(x => {return(<JournalIndivCols changeState={props.changeState} id={x} to={j3[x][0]} img={j3[x][1]} legend={j3[x][2]} key={x} />)})}
                    </div>
                    <br/><br/>    

                    <div id="aaa_journalNL2" className="aaa_journalRowCol clearfix">
                        <div className="colDate">May 21, 2017</div>
                        {Object.keys(j2).map(x => {return(<JournalIndivCols changeState={props.changeState} id={x} to={j2[x][0]} img={j2[x][1]} legend={j2[x][2]} key={x} />)})}
                    </div>
                    <br/><br/>  

                    <div id="aaa_journalNL1" className="aaa_journalRowCol clearfix">
                        <div className="colDate">Feb 21, 2017</div>
                        {Object.keys(j1).map(x => {return(<JournalIndivCols changeState={props.changeState} id={x} to={j1[x][0]} img={j1[x][1]} legend={j1[x][2]} key={x} />)})}
                    </div>
                    <br/><br/> 

                </div>
                <br/>
            </div>
        </div> 
    );
}

// const Articles = (props) => {
//     // console.log(props);
//     return(
//         // <div id="aaa_newsletterContainer" className="T600 clearfix" style={{ height: props.height+"px" }}>
//         // <div id="aaa_newsletterContainer" className="T600 clearfix" >
//         <div id="aaa_newsletterContainer" className="T600" >
//             <div id="z" />
//             <div id="journalBackButton" onClick={props.history.goBack} />
//             {/* <iframe title="aaaJournal" id="aaaJournal" src={"projects/aaa_newNL.html?"+props.url} seamless className="aaa_preserve3D clearfix" onLoad={props.resize} /> */}
//             <iframe title="aaaJournal" id="aaaJournal" src={"projects/aaa_newNL.html?"+props.url} seamless className="aaa_preserve3D" onLoad={props.resize} />
//         </div>
//     );
// }
const Articles2 = (props) => {
    return(
        <div id="aaa_newsletterContainer" className="T600" >
            <div id="z" />
            <div id="journalBackButton" onClick={props.history.goBack} />
            <iframe title="aaaJournal" id="aaaJournal" src={"projects/aaa_newNL.html?"+props.url} seamless className="aaa_preserve3D" onLoad={props.resize} />
        </div>
    );
}

class Journal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frame: '',
            frameContainer: '',
            url: ''
        };
    }
    componentDidMount(){
    }

    changeParams = (x) => {
        const projType = ['design','build','proj','ane','events'];
        let xx = x.replace('aaa_picNL','').split("_");  
        let aux = "cat="+projType[xx[1]-1]+"&nl="+xx[0];
        this.setState({url: aux});
    }
   
    resize = (props) => {
        let delayToResize = 1100;
        this.setState({frame: document.getElementById('aaaJournal')});
        this.setState({frameContainer: document.getElementById('aaa_newsletterContainer')});
        setTimeout(() => {
            // eslint-disable-next-line
            this.state.frameContainer.style.height = (100+this.state.frame.contentWindow.document.body.scrollHeight)+'px';  
            document.querySelector('#aaa_newsletterContainer').scrollIntoView({ behavior: 'smooth' });
        }, delayToResize);
    }

    render(props) { 
        return ( 
            <BrowserRouter>
                {/* DEBO llamar journalmenu desde aqui, no desde App2, ya que desde aqui puedo accesar a this.state! */}
                <Route path="/journal" render={(props) => <JournalMenu {...props} changeState={this.changeParams} />} />
                <Route path="/article" render={(props) => <Articles2 {...props} url={this.state.url} resize={this.resize} />} />
            </BrowserRouter>  
        );
    }
}

 
export default Journal;



    // <BrowserRouter>  
    //     <Route path="/journal"  component={JournalMenu} />
            
    //     <Route path="/designNL5" render={(props) => <Articles {...props} url="cat=design&nl=5" resize={this.resize} />} />
    //     <Route path="/buildNL5"  render={(props) => <Articles {...props} url="cat=build&nl=5" resize={this.resize}  />} />
    //     <Route path="/projNL5"   render={(props) => <Articles {...props} url="cat=proj&nl=5" resize={this.resize} />} />
    //     <Route path="/aneNL5"    render={(props) => <Articles {...props} url="cat=ane&nl=5" resize={this.resize} />} />
    //     <Route path="/eventsNL5" render={(props) => <Articles {...props} url="cat=events&nl=5" resize={this.resize} />} />
        
    //     <Route path="/designNL4" render={(props) => <Articles {...props} url="cat=design&nl=4" resize={this.resize} />} />
    //     <Route path="/buildNL4"  render={(props) => <Articles {...props} url="cat=build&nl=4" resize={this.resize} />} />
    //     <Route path="/projNL4"   render={(props) => <Articles {...props} url="cat=proj&nl=4" resize={this.resize}  />} />
    //     <Route path="/aneNL4"    render={(props) => <Articles {...props} url="cat=ane&nl=4" resize={this.resize}  />} />
    //     <Route path="/eventsNL4" render={(props) => <Articles {...props} url="cat=events&nl=4" resize={this.resize} />} />
        
    //     <Route path="/designNL3" render={(props) => <Articles {...props} url="cat=design&nl=3" resize={this.resize} />} />
    //     <Route path="/buildNL3"  render={(props) => <Articles {...props} url="cat=build&nl=3" resize={this.resize} />} />
    //     <Route path="/projNL3"   render={(props) => <Articles {...props} url="cat=proj&nl=3" resize={this.resize} />} />
    //     <Route path="/aneNL3"    render={(props) => <Articles {...props} url="cat=ane&nl=3" resize={this.resize}  />} />
    //     <Route path="/eventsNL3" render={(props) => <Articles {...props} url="cat=events&nl=3" resize={this.resize} />} />
        
    //     <Route path="/designNL2" render={(props) => <Articles {...props} url="cat=design&nl=2" resize={this.resize} />} />
    //     <Route path="/buildNL2"  render={(props) => <Articles {...props} url="cat=build&nl=2" resize={this.resize} />} />
    //     <Route path="/projNL2"   render={(props) => <Articles {...props} url="cat=proj&nl=2" resize={this.resize} />} />
    //     <Route path="/aneNL2"    render={(props) => <Articles {...props} url="cat=ane&nl=2" resize={this.resize} />} />
    //     <Route path="/eventsNL2" render={(props) => <Articles {...props} url="cat=events&nl=2" resize={this.resize} />} />
        
    //     <Route path="/designNL1" render={(props) => <Articles {...props} url="cat=design&nl=1" resize={this.resize} />} />
    //     <Route path="/buildNL1"  render={(props) => <Articles {...props} url="cat=build&nl=1" resize={this.resize} />} />
    //     <Route path="/aneNL1"    render={(props) => <Articles {...props} url="cat=ane&nl=1" resize={this.resize} />} />
    //     <Route path="/eventsNL1" render={(props) => <Articles {...props} url="cat=events&nl=1" resize={this.resize} />} /> 
    // </BrowserRouter> 




