import React, { Component } from 'react';
import "./contact.css";


class Email extends Component {
    state = {  
        mapOverlay: "",
        map: "",
        name: "",
        email: "",
        message: "",
        contact: "contact"
    }

    componentDidMount(){
        this.setState({ mapOverlay: document.getElementById('aaa_emailContainer') });
    }
   

    hideEmailOverlay = () => {
        // eslint-disable-next-line
        this.state.mapOverlay.classList.add("hideemailoverlay");
        setTimeout(()=>{ this.state.mapOverlay.classList.add("cssHide"); },600);
    }
    
    sendmail = (e) => {
        e.preventDefault();
        const aa104 = new FormData();
        aa104.append('aa_name', this.state.name);
        aa104.append('aa_email', this.state.email);
        aa104.append('aa_messg', this.state.message);
        aa104.append('formCode', this.state.contact);

        fetch("https://amadorarquitectos.com/Mail/aaa_FormProcessing.php", {
            method: "POST",
            body: aa104,
        }).then(res => {
            return res.text();
        }).then(data => {
            alert(data);
            this.setState({ name: '', email: '', message: '' });
            document.getElementById('aa10_4').reset();
            this.hideEmailOverlay();
        }).catch(err => {
            // Handle the error, if any
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value  });
    }

    render() { 
        // const { name, email, message } = this.state;
        return (
            <> 
                <div id="aaa_emailContainer" className="exanet_absT0L0W100H100M0P0 T400 cssHide hideemailoverlay">
                    <div id="aaa_contact0" className="">
                        <div id="_aaaContainerScroller_Contact" className="">
                            <br/>
                            <div className="contact">
                                <div className="closeEmailOverlay" onClick={ this.hideEmailOverlay }></div>
                                <div className="contacttitle">CONTACT</div>
                                <div className="aaa_form"> 
                                    Send us your message to: <br/><br/>
                                    <a rel="noopener noreferrer" target="_blank" href="mailto:info@amadorarquitectos.com">info @ amadorarquitectos.com</a>
                                    <br/><br/>
                                </div><br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Email;



                                    // <form id="aa10_4" name="aaform" onSubmit={this.sendmail} >
                                    //     <input id="aaa_name" required="" autoFocus="" type="text" name="name" placeholder="Full Name" maxLength="55" onChange={this.onChange} value={name} /><br/>
                                    //     <input id="aaa_email" required="" type="email" name="email" placeholder="e-Mail" maxLength="55"  onChange={this.onChange} value={email} /><br/>
                                    //     <textarea id="aaa_messg" required="" rows="7" name="message" placeholder=" Write your message" maxLength="300"   onChange={this.onChange} value={message}></textarea><br/>
                                    //     <input id="aaaReset" type="reset" value="" />
                                    //     <input type="submit" value="Submit" name="aaasend" id="aaa_send" />
                                    //     <input type="hidden" name="formCode" value="contact" />
                                    // </form> 

