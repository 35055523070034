import React from 'react';
// import PropTypes from 'prop-types';
import { detect } from 'detect-browser';
import './browser.css';

const result = detect(); 

const closeBrowserNote = () => {
  document.getElementById('note').style.display = 'none'  ;
}

const mybrowsers = ["chrome", "firefox",  "safari", "opera"];

const Browser = () => { 
  if(!mybrowsers.includes(result.name) ){
    return (
      <div id="note" className="browser" onClick={closeBrowserNote}>
        <div className="close" />
        For better User eXperience use<br/> 
        <span>Supported browsers:</span> Firefox, Chrome, Safari, Opera.
        <br/>
        <img src="img/misc/browsers.png" alt="" />
        <br/>
        <span style={{ marginTop: "15px" }}>Unsupported browsers:</span> 
        Windows Edge, Internet Explorer.
      </div>
    )
  }else{  return null; }
};

// Browser.propTypes = {};

Browser.defaultProps = {};

export default Browser;
