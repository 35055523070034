// import React, { Component, useRef } from 'react';
import React, { Component } from 'react';
// import clamp from 'lodash-es/clamp';
// import { useSprings, animated } from 'react-spring';
// import { useGesture } from 'react-use-gesture';
import Media from "react-media";
import "./projects.css";


// const Viewpager = (localprops) => {
//     const index = useRef(0);
//     // define your spring:  create multiple spring animations for static lists.
//     const [props, set] = useSprings(localprops.pages.length, i => ({ x: i * window.innerWidth, sc: 1, display: 'block' }));

//     const bind = useGesture(({ down, delta: [xDelta], direction: [xDir], distance, cancel }) => {
//         if (down && distance > window.innerWidth / 2)
//             cancel((index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, localprops.pages.length - 1)))
//         set(i => {
//             if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
//             const x = (i - index.current) * window.innerWidth + (down ? xDelta : 0);
//             const sc = down ? 1 - distance / window.innerWidth / 2 : 1;
//             return { x, sc, display: 'block' }
//         })
//     });
//     return props.map(({ x, display, sc }, i) => (
//         <animated.div {...bind()} key={i} style={{ display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`) }} className="pifia1">
//             <animated.div style={{ transform: sc.interpolate(s => `scale(${s})`), backgroundImage: `url(${"img/projects/"+localprops.category+"/"+localprops.pages[i]})` }}  className="pifia2" />
//         </animated.div>
//     ));
// }

const Viewpager = (localprops) => {
    const delay = 4;
    const duration = delay * localprops.pages.length;
    return localprops.pages.map((x,i) => <div className={"projectimages bg100"} key={i} style={{ backgroundImage: `url(${"img/projects/"+localprops.category+"/"+localprops.pages[i]})`, height: "100%", animationDuration: duration+"s",  animationDelay:  (i*delay)+"s" }} />);
}

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: {
                img: [],
                title: "",
                descriptionTitle: "",
                descriptionTitle_pt: "",
                description: "",
                description_pt: "",
                projsprite: ""
            },
            video: {
                prime507:   'FPwne0c8jVY',
                cubita:     'EtunfbvYzqo',
                greenhouse: 'U8OsjaGcEwM',
                mercedes:   'HFT0yvWybFI'
            },
            videoOverlay: null,
            haveVideo: 'aaaVideo cssHide'
        };

    }    
    
    // if ERROR, then check if chrome server is running on 8887
    componentDidMount() { 
        // const aaaURL="http://127.0.0.1:8887/json/";
        const aaaURL="json/";
        fetch(aaaURL+this.props.projectCat+this.props.projectName+'.json')
            .then(response => response.json())
            .then(w => {
                this.setState(prevState => ({project: {...prevState.project, img: w.img} }));
                this.setState(prevState => ({project: {...prevState.project, title: w.title} }));
                this.setState(prevState => ({project: {...prevState.project, descriptionTitle: w.descriptionTitle} }));
                this.setState(prevState => ({project: {...prevState.project, descriptionTitle_pt: w.descriptionTitle_pt} }));
                this.setState(prevState => ({project: {...prevState.project, description: w.description} }));
                this.setState(prevState => ({project: {...prevState.project, description_pt: w.description_pt} }));
                this.setState(prevState => ({project: {...prevState.project, projsprite: w.projsprite} }));
            })
            .catch(error => console.log(error));
        if( this.props.projectName === 'prime507'   || 
            this.props.projectName === 'cubita'     || 
            this.props.projectName === 'greenhouse' || 
            this.props.projectName === 'mercedes' ){
                this.setState({haveVideo: 'aaaVideo'});    
                this.setState({ videoOverlay: document.getElementById('videoOverlay') });    
        }  
        
    }

    goBack = () => {
        this.props.history.push("/"+this.props.projectCat.replace('/',''));
    }

    showVideo = () => {
        window.scrollTo(0, 0);
        let e = document.getElementById('videoOverlay');
        let f = e.children[0].children[0];
        e.classList.remove('cssHide');
        setTimeout(()=>{
            f.src = 'https://www.youtube.com/embed/'+this.state.video[this.props.projectName]+'?autoplay=1&amp;autohide=1&amp;rel=0';
            e.classList.remove('notVisible');
            e.classList.remove('zoomOut');
        },500);
    }

    putDescription = props => {
        return {__html: this.props.currentLang === 1? this.state.project.description:this.state.project.description_pt};
    }

    render(props) { 
        // console.log(this.props.currentLang);

        let styleSheet = document.createElement('style');
        styleSheet.type = 'text/css';
        let aux = this.state.project.img.length > 0? Math.ceil(100/this.state.project.img.length) : 0;
        let xxx = aux+"%";
        let yyy = (aux+3)+"%";
        let keyframes =
            `@-webkit-keyframes projectAnim {
                0%  { opacity: 0; } 
                2%  { opacity: 1;  } 
                ${xxx} { opacity: 1; ) } 
                ${yyy} { opacity: 0;  } 
                100% { opacity: 0; }
            }`;
        styleSheet.innerHTML = keyframes;
        document.getElementsByTagName('head')[0].appendChild(styleSheet);
        return ( 
            <div id="aaa_projectsContainer" className="exanetContainer clearfix exanetAnim2">
                <div className="aaa_portfolioTitle row clearfix">
                        <div id="backarrow" className="backarrow aaaBGCover" onClick={this.goBack}></div>
                    <div id="aaa_projTitle">{this.state.project.title}</div>
                </div>
                <div id="aaa_auxImgContainer">
                    {/* <div id="aaa_projImgContainer" className="clearfix"> */}
                    <div id="aaa_projImgContainer" className="">
                        {/* <img id="aaa_dummyImg0" src="img/misc/indivProjBGtransparent.png" alt="" className="responsive" /> */}
                        {/* <div id="aaa_projGalleryContainer"> */}
                            <Media query="(min-width: 500px)">
                                {matches => matches ? 
                                    (   <>
                                            <img id="aaa_dummyImg0" src="img/misc/indivProjBGtransparent.png" alt="" className="responsive" />
                                            <div id="aaa_projGalleryContainer">
                                                <Viewpager pages={this.state.project.img} category={this.props.projectCat} />
                                            </div>
                                        </>
                                    ) : 
                                    ( <img id="aaa_dummyImg0" src={"img/projects/"+this.props.projectCat+this.state.project.projsprite} alt="" className="aaa_rel responsive" /> )
                                }
                            </Media>
                            {/* <Viewpager pages={this.state.project.img} category={this.props.projectCat} /> */}
                        {/* </div> */}
                    </div>                    
                    <div id="aaa_projNext" className=""></div>
                    <div id="aaa_projPrev" className=""></div>
                    <div id="aaa_projLenght" className="">1/{this.state.project.img.length}</div>
                </div>
                <div className="row"><div id="aaa_projShare"></div></div>
                <div className="row h50">
                    <div id="projectVideo" className={this.state.haveVideo} onClick={this.showVideo}></div>
                </div>
                <br/>
                <div className="row">
                    <div id="aaa_projDescription" className="aaa_projDesc">
                        <span className='description3subtitle'>{this.props.currentLang === 1? this.state.project.descriptionTitle:this.state.project.descriptionTitle_pt}</span><br/>
                        <div dangerouslySetInnerHTML={this.putDescription()} />
                    </div>
                </div>
            </div> 
        );      
  
    }
}
 
export default Project;



