import React, { Component } from 'react';
import "./googlemap.css";

class GMap extends Component {
    constructor (props){
        super(props);
        this.state = {
            mapOverlay: "",
            page: "projects/aaa-googlemap.htm"
        };

    }      
 
    
    componentDidMount(){
        this.setState({ mapOverlay: document.getElementById('contactOverlay') });
    }

    closeMap = () => {
        this.state.mapOverlay.classList.add('zoomIN','notVisible');
        setTimeout(() => { this.state.mapOverlay.classList.add('cssHide'); },800);
    }

    render(props) { 
        return ( 
            <React.Fragment>
                <div id="contactOverlay" className="aaa_preserve3D T600 zoomIN cssHide notVisible">
                    <iframe title='aaaGoogleMap' id='googleMapContainer' seamless src="" />
                    <div id="googleMapClose" onClick={this.closeMap}></div>
                </div> 
            </React.Fragment>
        );
    }
}
 
export default GMap;


