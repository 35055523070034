import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import './oficinas.css';

class Oficinas extends Component {
  constructor (props){
      super(props);
      this.state = {
      };
  }

  componentDidMount(props){
    this.showTime();
 
  }

  // textoAdress = '<span id="infopty"><img alt="Panama city" src="img/misc/pty2.jpg" class="responsive" /><div id="MyClockDisplay" class="clock" onload="showTime()"></div> <strong>Panama</strong> <br/>Phones:  (507) 226.61.44 | (507) 226.14.11 <br/>  <em>info @ amadorarquitectos.com</em><br/> Panama City<br/>  Santa Maria Business District<br/>Prime 507 Bldg., Suite 604<br/> <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Amador+%2B+Arquitectos/@9.0284971,-79.4631055,17z/data=!3m1!4b1!4m5!3m4!1s0x8faca906e0756f79:0x20f4ddcf6ace5a21!8m2!3d9.0284971!4d-79.4609115">Google Map</a><br/></span>  <span id="infopt"><img alt="Panama city" src="img/misc/port1.jpg" class="responsive" /><div id="MyClockDisplay2" class="clock" onload="showTime()"></div>  <strong>Portugal</strong><br/>  Telefone:  (351) 936.360.057 <br/> <em>projetos @ amadorarquitectos.com</em><br/>  Rua das Mercês nº 41<br/>  9000-224 Funchal<br/>  Portugal<br/><a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/R.+das+Mercês+41,+9000-036+Funchal,+Portugal/@32.6513458,-16.9137692,17z/data=!3m1!4b1!4m5!3m4!1s0xc606009e5779607:0x38c6a8cfd3c1e408!8m2!3d32.6513458!4d-16.9115805">Map do Google</a><br/></span>'; 

  textoAdress = '<span id="infopty"><img alt="Panama city" src="img/misc/pty2.jpg" class="responsive" /><div id="MyClockDisplay" class="clock" onload="showTime()"></div> <strong>Panama</strong> <br/>Phones:  (507) 226.61.44 | (507) 226.14.11 <br/>  <em>info @ amadorarquitectos.com</em><br/> Panama City<br/>  Santa Maria Business District<br/>Prime 507 Bldg., Suite 604<br/> <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Amador+%2B+Arquitectos/@9.0284971,-79.4631055,17z/data=!3m1!4b1!4m5!3m4!1s0x8faca906e0756f79:0x20f4ddcf6ace5a21!8m2!3d9.0284971!4d-79.4609115">Google Map</a><br/></span>  <span id="infopt"><img alt="Panama city" src="img/misc/port1.jpg" class="responsive" /><br/></span>'; 

  
  showTime = () => {
    // *********** este bloque es para lidiar con el Daylight savings *********************
    var aux = new Date();
    var date = new Date(aux.getFullYear(),0, 1); 
    var date2 = new Date(aux.getFullYear(),6, 1); 
    var correctTimeZone = Math.max(date.getTimezoneOffset(), date2.getTimezoneOffset());  
    // ************************************************************************************
    var m = aux.getUTCMinutes(); // 0 - 59
    var s = aux.getUTCSeconds(); // 0 - 59
    // var h = date.getUTCHours(); // 0 - 23
    var utc = aux.getTime() + (correctTimeZone * 60000); 
    var panaGMT = -5; 
    var pana = new Date(utc + (panaGMT*3600000));  
    // var port = new Date(utc + (3600000*1));
    var pty = pana.getHours(); 
    // var prt = port.getHours();
    var session = ( pty < 12 ) ? "AM" : "PM";
    // var session2 = ( prt < 12 ) ? "AM" : "PM";
    pty = ( pty > 12 ) ? pty - 12 : pty;
    pty = ( pty === 0 ) ? 12 : pty;
    // prt = ( prt > 12 ) ? prt - 12 : prt;
    // prt = ( prt === 0 ) ? 12 : prt;


    pty = (pty < 10) ? "0" + pty : pty;
    // prt = (prt < 10) ? "0" + prt: prt;
    m = (m < 10) ? "0" + m : m;
    s = (s < 10) ? "0" + s : s;
    
    var time = pty + ":" + m + ":" + s + " " + session;
    // var timePortugal = prt + ":" + m + ":" + s + " " + session2;
    document.getElementById("MyClockDisplay").innerText = time;
    document.getElementById("MyClockDisplay").textContent = time;
    // document.getElementById("MyClockDisplay2").innerText = timePortugal;
    // document.getElementById("MyClockDisplay2").textContent = timePortugal;
    
    // XAVIER[10/MAY/2023]:  lo quite xq arrojaba error de que no cargaba el id="MyClockDisplay"
    // setTimeout(this.showTime, 2000);
    
}



  render(props) { 

      return ( 
        <div id="aaa_oficinasContainer" className="T400 animEntrance">
          <div className='encabezado'>{this.props.currentLang === 1? 'Contact':'Contato'}</div>
          <div id="infocontacto" dangerouslySetInnerHTML={{__html: this.textoAdress }} />
          {/* <div id="infocontacto">
            <span id="infopty"><img alt="Panama city" src="img/misc/pty2.jpg" class="responsive" /><div id="MyClockDisplay" class="clock" onload="showTime()"></div> <strong>Panama</strong> <br/>Phones:  (507) 226.61.44 | (507) 226.14.11 <br/>  <em>info @ amadorarquitectos.com</em><br/> Panama City<br/>  Santa Maria Business District<br/>Prime 507 Bldg., Suite 604<br/> <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Amador+%2B+Arquitectos/@9.0284971,-79.4631055,17z/data=!3m1!4b1!4m5!3m4!1s0x8faca906e0756f79:0x20f4ddcf6ace5a21!8m2!3d9.0284971!4d-79.4609115">Google Map</a><br/></span>  <span id="infopt"><img alt="Panama city" src="img/misc/port1.jpg" class="responsive" /><br/></span>
          </div> */}
        </div>
      );
  }
}

export default Oficinas;
