import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ReusableLink2 } from "../../../globals";
import Project from "../project";


const projects = [
    {
        "Ponce Clinic":    ['/ponce','aaa_portfolioIntPic6.jpg'],
        "Dominguez Bello": ['/dbello','aaa_portfolioIntPic7.jpg'],
        "Tucan Hotel":     ['/tucan','aaa_portfolioIntPic4.jpg'],    
    },
    {
        "Tucan Tee": ['/tucantee','aaa_portfolioIntPic12.jpg'],
        "Ponce Clinic 2": ['/cponce','aaa_portfolioIntPic14.jpg'],
        "The Groove": ['/grove','aaa_portfolioIntPic13.jpg'],
    },
    {
        "GEA Group":  ['/gea','aaa_portfolioComPic9.jpg'],
        "Cacao Cafe": ['/cacao','aaa_portfolioComPic8.jpg'],
    }
]

const intProjects = ["cacao","gea","grove","tucan","ponce","dominguezb","tucantee", "cponce"];


export const IntMenu = () => {
    return (
        <div className="exanetAnim1">
            <div className="categoryTitle goggleOpenSans">Interiors</div>
            <div className="row clearfix">
                { Object.keys(projects[0]).map(key => {return( <ReusableLink2 to={projects[0][key][0]} category={key} img={projects[0][key][1]} key={key} /> )})}
            </div>
            <br/>
            <div className="row clearfix">
                { Object.keys(projects[1]).map(key => {return( <ReusableLink2 to={projects[1][key][0]} category={key} img={projects[1][key][1]} key={key} /> )})}
            </div>
            <br/>
            <div className="row clearfix">
                { Object.keys(projects[2]).map(key => {return( <ReusableLink2 to={projects[2][key][0]} category={key} img={projects[2][key][1]} key={key} /> )})}
            </div>                
        </div>
    );
}


class Interiors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    render(props) { 
        return ( 
            <>
                { intProjects.map(x => { return(<Route path={"/"+x} key={x} render={(props)=> <Project {...props} projectCat="int/" projectName={x} currentLang={this.props.currentLang}  />} /> )}) }
            </>
        );
    }
}
 
export default Interiors;