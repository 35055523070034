import React from 'react';
import { NavLink } from 'react-router-dom';

const ReusableLink = (props) => {
    // console.log(props.currentLang);
    const showHistory = () => {
        // console.log('x');
    }
    return(
        <div className="imgContainer2">
            <NavLink to={props.to} innerRef={showHistory} >
                <figure>
                    <img alt={props.category} src={"../img/mozaico/" + props.img} className="responsive" />
                    <div className="projectTitle">{props.category}</div>
                </figure>
            </NavLink>
        </div>
    )
}
export const ReusableLink2 = (props) => {
    return(
        <div className="imgContainer">
            <NavLink to={props.to} className="noDecor">
                <figure>
                    <img alt={props.category} src={"../img/mozaico/" + props.img} className="responsive" />
                    <figcaption className="figureTitle">{props.category}</figcaption>
                </figure>
            </NavLink>
        </div>
    )
}
export const ReusableLink3 = (props) => {
    return(
        <div className="homeImgContainer">
            <figure onClick={props.dumbToggle}>
                <img alt={props.category} src={"../img/mozaico/" + props.img} className="responsive" />
                <div className="T200" >{props.text}</div>
            </figure>
        </div>
    )
}
export const ReusableLink4 = (props) => {
    return(
        <div className="imgContainer">
            <figure onClick={props.dumbToggle}>
                <img alt={props.category} src={"../img/mozaico/" + props.img} className="responsive" />
                <div className="T200">{props.text}</div>
            </figure>
        </div>
    )
}

export const ReusableLink5 = (props) => {
    return(
        <div className="imgContainer">
            <NavLink to={props.to} className="noDecor">
                <figure>
                    <img alt={props.category} src={"../img/" + props.img} className="responsive" />
                    <figcaption className="figureTitle">{props.category}</figcaption>
                </figure>
            </NavLink>
        </div>
    )
}

export const m = (params) => {
    console.log(params);
}



export let getDevProps = (params) => {
    let device = {
        vpW: 0, 
        vpH: 0, 
        smartphoneMax: 768, 
        tabletMax:  1024,
        portrait: null, 
        desktop: null,
        tbP: null, 
        spP: null       
    };   

    if (typeof window.innerWidth !== 'undefined') {
            device.vpW = window.innerWidth;
            device.vpH = window.innerHeight;
    }else if (typeof document.documentElement !== 'undefined' && 
                    typeof document.documentElement.clientWidth !== 'undefined' && 
                    document.documentElement.clientWidth !== 0){
             // IE6_standardsCompliantMode (i.e. 1rst-line of doc = valid doctype)
             device.vpW = document.documentElement.clientWidth;
             device.vpH = document.documentElement.clientHeight;
    }else { // IE < 6
             device.vpW = document.getElementsByTagName('body')[0].clientWidth;
             device.vpH = document.getElementsByTagName('body')[0].clientHeight;
    }
    // ORIENTATION
    device.portrait = (device.vpW <= device.vpH) ? 1 : 0;
    // DESKTOP, TABLET, SMARTPHONE
    if(device.vpW > device.tabletMax && !device.portrait){ device.desktop = 1; device.tbP = null; device.spP = null; };
    if(device.vpW <= device.tabletMax){
        device.desktop = null;
        if (device.vpW >= device.smartphoneMax){
            device.spP = null; device.tbP = (device.portrait === 1) ? 1 : 0
        }else{
            device.tbP = null; device.spP = (device.portrait === 1) ? 1 : 0;
        }
    };
    return device;
};


export default ReusableLink ;
