import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ReusableLink2 } from "../../../globals";
import Project from "../project";

const projects = [
    {
        "Prime 507": ['/prime507','aaa_portfolioComPic12.jpg'],    
        "Mar del Sur Business Center": ['/mardelsur','aaa_portfolioComPic1.jpg'],
        "Tucan Tee":  ['/ctucantee','aaa_portfolioComPic14.jpg'],
    },
    {
        "La Plaza Cubita": ['/cubitamall','aaa_portfolioComPic7.jpg'],
        "IGRA":  ['/igra','aaa_portfolioComPic5.jpg'],
        "Paseo Las Mercedes": ['/mercedes','aaa_portfolioComPic10.jpg'],
    },
   {
        "EPK": ['/epk','aaa_portfolioComPic6.jpg'],
        "Bal Harbour Plaza":  ['/balharbour','aaa_portfolioComPic2.jpg'],
        "Prival Group":   ['/prival','aaa_portfolioComPic4.jpg'],
    }
]

const comProjects = ["mardelsur","ctucantee","balharbour","prival","igra","epk","cubitamall","prime507","mercedes"];

export const ComMenu = () => {
    return (
        <div className="exanetAnim1">
            <div className="categoryTitle goggleOpenSans">Commercial</div>
            <div className="row clearfix">
                { Object.keys(projects[0]).map(key => {return( <ReusableLink2 to={projects[0][key][0]} category={key} img={projects[0][key][1]} key={key} /> )})}
            </div>
            <br/>
            <div className="row clearfix">
                { Object.keys(projects[1]).map(key => {return( <ReusableLink2 to={projects[1][key][0]} category={key} img={projects[1][key][1]} key={key} /> )})}    
            </div>
            <br/>
            <div className="row clearfix"> 
                { Object.keys(projects[2]).map(key => {return( <ReusableLink2 to={projects[2][key][0]} category={key} img={projects[2][key][1]} key={key} /> )})}         
            </div>              
        </div>                
                 
    );
}


class Commercial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    render(props) { 
        return ( 
            <>
                {/* Xavier[15/Ago/2022]:  aqui se llama a  'project.jsx'  que esta en el directorio  comps/portfolio    */}
                { comProjects.map(x => { return(<Route path={"/"+x} key={x} render={(props)=> <Project {...props} projectCat="com/" projectName={x} currentLang={this.props.currentLang}  />} /> )}) }
            </>                
        );
    }
}
 
export default Commercial;