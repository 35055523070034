import React from 'react';
import './videos.css';

// **************** PROJECT VIDEOS *******************
const Close = (e) => {
    let elem = document.getElementById('videoOverlay');
    elem.classList.add('zoomOut');
    setTimeout(()=>{
        elem.children[0].children[0].src = '';
        elem.classList.add('notVisible');
        elem.classList.add('cssHide');
    },900);
}

const Videos = () => {
    return (
        <div id="videoOverlay" className="zoomOut cssHide notVisible T800" onClick={Close} >
            <div className="aaa_youtubeVideo">
                <iframe src='' frameBorder="0" allowFullScreen title="video" />
            </div>
        </div>
    );
}

export default Videos;