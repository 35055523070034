import React, { Component } from 'react';
import "./careers.css";


const AvailableJobs = () => {
    return(
        <React.Fragment>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div id="aaa_careers2T">
                    <strong>Available Positions</strong><br/><span className="permtemp">([P] = Permanent,  [T] = Temporary)</span>
                </div>
                <p id="aaa_careers2"></p>
                
                <table id="job1" className="aaaJobTables">  
                    <tbody>
                        <tr className="h40"><td className="aaa_careersJobTitle" colSpan="2">DEVELOPMENT ASSISTANT [P] - Code AAA#103</td></tr>
                        <tr className="h30"><td className="aaa_jobCode_RequirementsTD">Experience</td><td id="" className="aaa_minExp aaa_otroColor">&gt; 4 years</td></tr>
                        <tr className="h30"><td className="aaa_jobCode_RequirementsTD" colSpan="2">Duties</td></tr>
                        <tr className="h30"><td colSpan="2" id="aaa_jobDuties" className="aaa_otroColor">Elaboration of plans of Architecture, Structure and Electromechanical Systems.</td></tr>
                        <tr className="h30"><td className="aaa_jobCode_RequirementsTD" colSpan="2">Requirements</td></tr>
                        <tr><td colSpan="2" id="aaa_jobReqs" className="aaa_otroColor"><ul><li>Architecture Licenciate.</li><li>Good knowledge of AUTOCAD, ArchiCAD software.</li><li>Creative, Responsible, Organized, Proactive, Diligent, Ability to work under pressure.</li></ul></td></tr>
                    </tbody>
                </table>
                <br/>
                <table id="job2" className="aaaJobTables"><tbody>
                <tr className="h40"><td className="aaa_careersJobTitle" colSpan="2">DESIGN ASSISTANT [P] - Code AAA#200</td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD">Experience</td><td id="" className="aaa_minExp aaa_otroColor">&gt; 2 years</td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD" colSpan="2">Duties</td></tr>
                <tr className="h30"><td colSpan="2" id="aaa_jobDuties" className="aaa_otroColor">Elaboration of plans of Architecture, Structure and Electromechanical Systems.</td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD" colSpan="2">Requirements</td></tr>
                <tr><td colSpan="2" id="aaa_jobReqs" className="aaa_otroColor"><ul><li>Senior Architecture Student.</li> <li>Knowledge and Management of Design Tools (3D studio, 2D Autocad, Photoshop, ArchiCad / Revit).</li><li> Experience to deliver jobs in the established period.</li> <li>Creative, Responsible, Organized, Proactive, Diligent, Ability to work under pressure. </li> </ul></td></tr>
                </tbody></table>
                <br/>
                <table id="job3" className="aaaJobTables"><tbody>
                <tr className="h40"><td className="aaa_careersJobTitle" colSpan="2">PLAN DEVELOPER [P] - Code AAA#201</td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD">Experience</td><td id="" className="aaa_minExp aaa_otroColor">&gt; 4 years</td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD" colSpan="2">Duties</td></tr>
                <tr className="h30"><td colSpan="2" id="aaa_jobDuties" className="aaa_otroColor">Elaboration of plans of Architecture, Structure and Electromechanical Systems.</td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD" colSpan="2">Requirements</td></tr>
                <tr><td colSpan="2" id="aaa_jobReqs" className="aaa_otroColor"><ul><li>Management of AUTOCAD software</li> <li>Knowledge and management of ArchiCad / Revit. </li> <li>Creative, Responsible, Organized, Proactive, Diligent, Ability to work under pressure.</li></ul></td></tr>
                </tbody></table>
                <br/>
                <table id="job4" className="aaaJobTables"><tbody>
                <tr className="h40"><td className="aaa_careersJobTitle" colSpan="2">PROJECT MANAGER + QC (Quality Control) [P] - Code AAA#202</td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD">Experience</td><td id="" className="aaa_minExp aaa_otroColor">&gt; 4 years</td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD" colSpan="2">Duties</td></tr>
                <tr className="h30"><td colSpan="2" id="aaa_jobDuties" className="aaa_otroColor">Operational Management of the Firm concerning the Design and Development of Construction documents (CD): <ul><li>elaboration of the work flow,</li> <li>achievement of deadlines in CD delivering, </li> <li>coordination with consultants, </li> <li>QC of consultant deliveries, </li><li>Review of Designs, CD, civil works budgets, technical specifications.</li><li>Coordination with the National Budget Office for project progress.</li></ul> <strong> <u>Support to General Management in</u></strong>: <ul> <li>Design development leveraging a team of architects, </li> <li>Elaboration of technical specifications, Attendance to work meetings</li></ul></td></tr>
                <tr className="h30"><td className="aaa_jobCode_RequirementsTD" colSpan="2">Requirements</td></tr>
                <tr><td colSpan="2" id="aaa_jobReqs" className="aaa_otroColor">A <strong>non-average</strong> level is required in: <ul><li>Handling of personal work relationships for <u>Personnel Administration</u> (7 architects). </li><li>Mediation in conflictive situations. Inspire, by example, the staff.</li><li>Creating leaders within the organization.</li><li> Structuring workflow processes.</li> <li>Management of digital tools for Design development &amp; DC (<em> ArchiCAD, Photoshop, AutoCAD, Project </em>) </li> <li>Organization. Focus in daily work. Improvement &amp; constant learning.</li><li>Creativity, Proactivity, Diligence, Loyalty. Sense of Responsibility and Duty. Sense of discretion. Understanding the intimacies of work under pressure. Tolerance to correction. Teamwork. Creation of a dynamic, participative, open, creative environment.</li></ul> <strong>Benefits</strong> <br/> <ul> <li>Freedom to constantly break the limits of what has been learned.</li><li>Form part of a Firm that is characterized by the footprint that leaves in the Society for its quality of designs.</li><li> Flexibility of schedule. </li><li>Training.</li></ul></td></tr>
                </tbody></table>
            </div>
        </React.Fragment>
    );
}

class Carrers extends Component {
    state = {  }
    componentDidMount(){ }

    langText = {
        english: [
            'We offer many opportunities for those who are prepared. If you believe you qualify for any of the listed jobs, you are invited to apply. ALL the fields of the following application form are necessary. The information requested will be used to verify your professional background.',
            'No Available Jobs for the moment.',
            'Application Form',
            'Use it to send us your basic data with your attached application. This form will send everything automatically to our email address.',
            'Click over the image to show the form',
            'WARNING: for SECURITY reasons, the form DOES NOT work on mobile devices',
            'In order to speed up the selection process, follow the instructions below: <strong style="text-decoration: underline;">Send 1 file only</strong>, in <strong> PDF </strong> format (Maximum size of the file: 500 KB), with your <strong> <em> Cover Letter </em> </strong> (1 page maximum, A4 size), your <strong> <em> CV / Resume </em> </strong> (2 pages maximum, A4 size) and, if available, your <strong> <em> Portfolio </em> </strong> (5 pages maximum, A4 size). <strong> Total of pages </strong>: <u> eight (8) </u>. <br/> <br/> If you are selected for the second revision phase, we will contact you to request more information.  <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <p> <strong> Diversity and Privacy Policies</strong>: Our company does not discriminate on the basis of race, religion, color, sex, age or disability. All qualifying applications will be treated under the policy of <em>EQUAL OPPORTUNITIES</em>&nbsp;&nbsp; and selection decisions will be based only on factors related to the job. At the moment we can accept only national / Panamanian applicants. All your information will be kept absolutely confidential in our archives and nothing will be sent or filtered, under any circumstance, to other companies or governmental institutions of any kind, national or foreign.</p>'],
        portugues: [
            'Oferecemos muitas oportunidades para aqueles que estão preparados. Se acredita que se qualifica para qualquer um dos trabalhos apresentados, está convidado a candidatar-se. TODOS os campos do formulário de inscrição a seguir são necessários. As informações solicitadas serão usadas para verificar sua formação profissional.',
            'Nenhum trabalho disponível no momento.',
            'Formulário de aplicação',
            'Use-o para nos enviar seus dados básicos com o seu aplicativo anexado. Este formulário enviará tudo automaticamente para o nosso endereço de e-mail.',
            'Clique na imagem para visualizar o formulário',
            'AVISO: por motivos de SEGURANÇA, o formulário NÃO funciona em dispositivos móveis.',
            'Para agilizar o processo de seleção, siga as instruções abaixo: <strong style="text-decoration: underline;">Envie apenas 1 arquivo</strong>, em formato <strong>PDF</strong> (tamanho máximo do arquivo: 500 KB), com sua <strong><em>Carta de Apresentação</em></strong> (máximo de 1 página, tamanho A4), seu <strong> <em>CV / Currículo</em></strong>  (máximo de 2 páginas, tamanho A4) e, se disponível, o seu <strong> <em>Portefólio</em></strong> (máximo de 5 páginas, tamanho A4). Total de páginas:  <u>oito (8) </u>. <br/><br/> Se for selecionado para a segunda fase de revisão, entraremos em contato para solicitar mais informações. <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <p><strong>  Políticas de Diversidade e Privacidade</strong>: A nossa empresa não discrimina com base em raça, religião, cor, sexo, idade ou deficiência. Todas as candidaturas qualificadas serão tratadas de acordo com a política de IGUALDADE DE OPORTUNIDADES e as decisões de seleção serão baseadas apenas em fatores relacionados ao trabalho. No momento, podemos aceitar apenas candidatos nacionais / panamenhos. Todas as suas informações serão mantidas em absoluto sigilo nos nossos arquivos e nada será enviado ou filtrado, em momento algum, para outras empresas ou instituições governamentais de qualquer natureza, nacionais ou estrangeiras.</p>'
        ]
    };

    render(props) { 
        // console.log(this.props.currentLang);
        return ( 
            <div id="aaa_careersContainer" className="T400 animEntrance">
                <div id="aaa_careers0" className="row">
                    <p id="aaa_careers1">{ this.props.currentLang === 1? this.langText.english[0]:this.langText.portugues[0] }<br/></p>
                </div>
                <br/>
                <br/>
                <div id="aaa_availableJobs" className=""><strong>{ this.props.currentLang === 1? this.langText.english[1]:this.langText.portugues[1] }</strong></div>
                <br/><br/>
                <div id="aaa_careers2x" className="cssHide">
                    <AvailableJobs />
                </div>
                <br/><br/>
                <hr className="aaa_hr" />
                <div id="aaa_careers3" className="row">
                    <div id="aaa_careers3_0">
                        <div id="aaa_careers3T">{ this.props.currentLang === 1? this.langText.english[2]:this.langText.portugues[2] }</div>
                        { this.props.currentLang === 1? this.langText.english[3]:this.langText.portugues[3] }<br/><br/>
                        <div className="clickToSeeForm">{ this.props.currentLang === 1? this.langText.english[4]:this.langText.portugues[4] }</div>
                    </div>
                    <div id="aaa_careers3_2">
                        <div id="aaa_careersSendDoc" className="BGCover"></div>
                    </div>
                    <div id="aaa_careers3_1">
                        <div className="aaa_aux1"><span><strong>{ this.props.currentLang === 1? this.langText.english[5]:this.langText.portugues[5] }</strong>.</span></div>
                        <br/>
                        <div id="aaa_careers3Text" dangerouslySetInnerHTML={{ __html: this.props.currentLang === 1? this.langText.english[6]:this.langText.portugues[6] }} />
                    </div>
                </div>
            </div>


        );
    }
}
 
export default Carrers;