import React, { Component } from 'react';
import "./volunteer.css";


const contentStruct = {
    item4: {
        id: 'aaa_volunteer3',
        imgID: 'aaa_urbWS2020',
        imgSRC: '../../img/volunteering/aaa_volunteeringMain4.jpg',
        eng_content:  '<strong  className="font14">Urbanism Workshop 2020</strong><br/><br/>Our participation in the new <strong>As Built Urbanism Workshop</strong>, University of Panama, is refreshing.<br/><br/><strong>Theme</strong> <br/>To explore the possibility of recovering and taking advantage of abandoned infrastructures, to promote the creation of quality social housing, well connected to the public transport system, with community centers, and places of recreation. <br/> <em>Photos soon...</em>',
        pt_content: '<strong  className="font14">Workshop de Urbanismo 2020</strong><br/><br/>A nossa participação no novo <strong>Workshop de Urbanismo As Construído</strong>, da Universidade do Panamá, é revigorante. <br/><br/><strong>Tema</strong> <br/> Explorar a possibilidade de recuperação e aproveitamento de infraestruturas abandonadas, promover a criação de habitação social de qualidade, bem ligada ao sistema de transportes públicos, com centros comunitários e locais de lazer.<br/> <em>Fotos em breve...</em>'
    },

    item3:{
        id: 'aaa_volunteer3',
        imgID: 'aaa_urbWS2019',
        imgSRC: '../../img/volunteering/aaa_volunteeringMain1.jpg',
        eng_content:  '<strong  className="font14">Urbanism Workshop 2019</strong><br/><br/>We participated again as a visiting Professor in the <strong>As Built Urbanism Workshop</strong> of the University of Panama.<br/><br/><strong>Theme</strong> <br/>The retrofit of the area of incidence of the Metro station of Villa Lucre from the creation of a TOD (Transit Oriented Development) <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/2037903096327070" target="_blank" rel="noopener noreferrer"><em>Photos</em></a>',
        pt_content: '<strong  className="font14">Workshop de Urbanismo 2019</strong><br/><br/>Participamos novamente como Professor Visitante no <strong>Workshop de Urbanismo As Construído</strong>  da Universidade do Panamá.<br/><br/><strong>Tema</strong> <br/>O retrofit da área de incidência da estação do Metrô de Villa Lucre a partir da criação de um TOD (Desenvolvimento Orientado ao Trânsito) <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/2037903096327070" target="_blank" rel="noopener noreferrer"><em>Fotos</em></a>'
    },

    item2:{
        id: 'aaa_volunteer2',
        imgID: 'aaa_urbWS2018',
        imgSRC: '../../img/volunteering/aaa_volunteeringMain2.jpg',
        eng_content:  '<strong  className="font14">Urbanism Workshop 2018</strong><br/><br/>We appreciate the invitation from the <strong>Faculty of Architecture and Design</strong> of the University of Panama to lead one of the <strong>As Build</strong> courses of the <strong>Urbanism Workshop</strong>, Summer 2018. Through this opportunity we reaffirm our commitment to build a better city and therefore a better society, planting in the young renewed seeds about the way in which our cities are understood. <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/1577405895710128?__xts__%5B0%5D=68.ARD2b6yVjCVL18nq5R6n1a4IRz2z-Lf9F-8gUvUZywuCp2F6f7KzF9kDmanuhf3AA1q_ZV2IeQtRXhOe_OihqcL8TZ4CyLO0buRKrR15OO9FMTlXYhkcb9dB4k5fo3R8f4tyEMRMJ9ff2DDoOVu1zMuJF9id6QJhC3ytGq-_pfKgRkG2Wsxs7uZQlPFRBV1yFhBXMNj7yyQeQ51qTv-1KZ3VIxbZPgEkSndVIVJ9K-3Jv-PBsWfYlpYYI5hLOe-YzZ8pjAXWTbrZTRuwfqz3a8u7W9ZUCbWu8K09Y6710K7oOIIzENcvEqVFSRsQgvc5sA8YWWdaBMs4BxrgcQpjE5kFXBvZ86WbNKLmBvWg96dBXJLnaAgsqop-S1RjlQEMSvj7X3JZymaHVplZQrHPNTdHpSRWE4H0MYRJ7GNd6yhIAs3e&amp;__tn__=-R" target="_blank" rel="noopener noreferrer"><em>Photos</em></a>',
        pt_content: '<strong  className="font14">Workshop de Urbanismo 2018</strong><br/><br/>Agradecemos o convite da <strong>Faculdade de Arquitetura e Design</strong> do Panamá para liderar um dos cursos <strong>As Build</strong> do <strong>Workshop de Urbanismo</strong>, Verão 2018. Através desta oportunidade reafirmamos o nosso compromisso de construir uma cidade melhor e, portanto, uma sociedade melhor, plantando nos jovens sementes renovadas sobre a forma como as nossas cidades são compreendidas. <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/1577405895710128?__xts__%5B0%5D=68.ARD2b6yVjCVL18nq5R6n1a4IRz2z-Lf9F-8gUvUZywuCp2F6f7KzF9kDmanuhf3AA1q_ZV2IeQtRXhOe_OihqcL8TZ4CyLO0buRKrR15OO9FMTlXYhkcb9dB4k5fo3R8f4tyEMRMJ9ff2DDoOVu1zMuJF9id6QJhC3ytGq-_pfKgRkG2Wsxs7uZQlPFRBV1yFhBXMNj7yyQeQ51qTv-1KZ3VIxbZPgEkSndVIVJ9K-3Jv-PBsWfYlpYYI5hLOe-YzZ8pjAXWTbrZTRuwfqz3a8u7W9ZUCbWu8K09Y6710K7oOIIzENcvEqVFSRsQgvc5sA8YWWdaBMs4BxrgcQpjE5kFXBvZ86WbNKLmBvWg96dBXJLnaAgsqop-S1RjlQEMSvj7X3JZymaHVplZQrHPNTdHpSRWE4H0MYRJ7GNd6yhIAs3e&amp;__tn__=-R" target="_blank" rel="noopener noreferrer"><em>Fotos</em></a>'
    },

    item1:{
        id: 'aaa_volunteer1',
        imgID: 'aaa_orphanage',
        imgSRC: '../../img/volunteering/aaa_volunteeringMain3.jpg',
        eng_content:  '<strong className="font14">New Horizons Orphanage</strong><br/><br/>In the Tocumen area, the orphanage, despite multiple needs, tries to be self-sufficient.  <br/> <br/> <strong>Contribution</strong>  <br/>Reconditioning of area for livestock to have an appropriate place to produce consumables, generating in this way a permanent income. Part of the space is devoted to corn planting, egg farming and goat cattle feed.  <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/1247540582029996?__xts__%5B0%5D=68.ARAgFJ89OvK1BJesxpUYnA1OOJhLty-X4O1U5Bg_tA0PXTacIkZnh8GtpQkWGj8lKJySzDExDOTGU5Tc7xD9oBfSef4wacqNAMM4jFwb8ldnO5Dmjp_WyPsluUNzjAoMXODSUl8VT6ueG21OjLjdtiO8SksHGS1pL5fuyPe-33BiQfxz70GYV1NzkyvslWbo5wYBZ37Q1pRQPBpTdEp7oVD14Cjb-CwyS23QYgG8z7aajFP4bRlP7ANMhj_xyLXnejAM9DmwAyM0C6ieeFCKfx34vV0r6BHryImB3FglSGzhRwCvnw0Vi8SqBX11ZrknJOuoeh456ca2Udg8fhCkjLyeGRqPXhqis-R5JZyABxOeaVzxBmMcicpEekyaJrWtsai273uQrP4tEgBBkYKikDBeL7k5WzpsYUaPQYSU1zfwpSvmtY2Toy08n3j3vYNYBu19_QAoq_CXwBMwyZKv&amp;__tn__=-R" target="_blank" rel="noopener noreferrer"><em>Photos</em></a>',
        pt_content: '<strong className="font14">Orfanato Horizontes</strong><br/><br/>Na área de Tocumen, o orfanato, apesar das múltiplas necessidades, tenta ser autossuficiente.  <br/> <br/> <strong>Contribuição</strong>  <br/>Recondicionamento de área para pecuária para ter local apropriado para produção de insumos, gerando assim uma renda permanente. Parte do espaço é dedicado ao plantio de milho, criação de ovos e alimentação de gado caprino.  <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/1247540582029996?__xts__%5B0%5D=68.ARAgFJ89OvK1BJesxpUYnA1OOJhLty-X4O1U5Bg_tA0PXTacIkZnh8GtpQkWGj8lKJySzDExDOTGU5Tc7xD9oBfSef4wacqNAMM4jFwb8ldnO5Dmjp_WyPsluUNzjAoMXODSUl8VT6ueG21OjLjdtiO8SksHGS1pL5fuyPe-33BiQfxz70GYV1NzkyvslWbo5wYBZ37Q1pRQPBpTdEp7oVD14Cjb-CwyS23QYgG8z7aajFP4bRlP7ANMhj_xyLXnejAM9DmwAyM0C6ieeFCKfx34vV0r6BHryImB3FglSGzhRwCvnw0Vi8SqBX11ZrknJOuoeh456ca2Udg8fhCkjLyeGRqPXhqis-R5JZyABxOeaVzxBmMcicpEekyaJrWtsai273uQrP4tEgBBkYKikDBeL7k5WzpsYUaPQYSU1zfwpSvmtY2Toy08n3j3vYNYBu19_QAoq_CXwBMwyZKv&amp;__tn__=-R" target="_blank" rel="noopener noreferrer"><em>Fotos</em></a>'
    }
}


// const Volunteer4 = (e)=> {
//     return(
//         <div id="aaa_volunteer3" className="T600 clearfix">
//             <div className="Vcol2 Vcol2L col2sizeL">
//                 <figure>
//                 <img id="aaa_urbWS2020" alt="" className="volunt responsive cursordefault" src="../../img/volunteering/aaa_volunteeringMain4.jpg"  />
//                 </figure>
//             </div>
//             <div className="Vcol3 Vcol2R col2sizeR">
//                 <strong  className="font14">Urbanism Workshop 2020</strong><br/><br/>Our participation in the new <strong>As Built Urbanism Workshop</strong>, University of Panama, is refreshing.<br/><br/><strong>Theme</strong> <br/>
//                 To explore the possibility of recovering and taking advantage of abandoned infrastructures, to promote the creation of quality social housing, well connected to the public transport system, with community centers, and places of recreation. <br/> <em>Photos soon...</em>
//             </div>
//         </div>
//     );
// }

// const Volunteer3 = ()=> {
//     return(
//         <div id="aaa_volunteer3" className="T600 clearfix">
//             <div className="Vcol2 Vcol2L col2sizeL">
//                 <figure>
//                 <img id="aaa_urbWS2019" alt="" className="volunt responsive cursordefault" src="../../img/volunteering/aaa_volunteeringMain1.jpg"  />
//                 </figure>
//             </div>
//             <div className="Vcol3 Vcol2R col2sizeR">
//                 <strong  className="font14">Urbanism Workshop 2019</strong><br/><br/>We participated again as a visiting Professor in the <strong>As Built Urbanism Workshop</strong> of the University of Panama.<br/><br/><strong>Theme</strong> <br/>The retrofit of the area of incidence of the Metro station of Villa Lucre from the creation of a TOD (Transit Oriented Development) <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/2037903096327070" target="_blank" rel="noopener noreferrer"><em>Photos</em></a>
//             </div>
//         </div>
//     );
// }

// const Volunteer2 = ()=> {
//     return(
//         <div id="aaa_volunteer2" className="T600 clearfix">
//             <div className="Vcol2 Vcol2L col2sizeL">
//                 <figure>
//                     <img id="aaa_urbWS2018" alt="" className="volunt responsive cursordefault" src="../../img/volunteering/aaa_volunteeringMain2.jpg" />
//                 </figure>
//             </div>
//             <div className="Vcol3 Vcol2R col2sizeR">
//                 <strong  className="font14">Urbanism Workshop 2018</strong><br/><br/>We appreciate the invitation from the <strong>Faculty of Architecture and Design</strong> of the University of Panama to lead one of the <strong>As Build</strong> courses of the <strong>Urbanism Workshop</strong>, Summer 2018. Through this opportunity we reaffirm our commitment to build a better city and therefore a better society, planting in the young renewed seeds about the way in which our cities are understood. <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/1577405895710128?__xts__%5B0%5D=68.ARD2b6yVjCVL18nq5R6n1a4IRz2z-Lf9F-8gUvUZywuCp2F6f7KzF9kDmanuhf3AA1q_ZV2IeQtRXhOe_OihqcL8TZ4CyLO0buRKrR15OO9FMTlXYhkcb9dB4k5fo3R8f4tyEMRMJ9ff2DDoOVu1zMuJF9id6QJhC3ytGq-_pfKgRkG2Wsxs7uZQlPFRBV1yFhBXMNj7yyQeQ51qTv-1KZ3VIxbZPgEkSndVIVJ9K-3Jv-PBsWfYlpYYI5hLOe-YzZ8pjAXWTbrZTRuwfqz3a8u7W9ZUCbWu8K09Y6710K7oOIIzENcvEqVFSRsQgvc5sA8YWWdaBMs4BxrgcQpjE5kFXBvZ86WbNKLmBvWg96dBXJLnaAgsqop-S1RjlQEMSvj7X3JZymaHVplZQrHPNTdHpSRWE4H0MYRJ7GNd6yhIAs3e&amp;__tn__=-R" target="_blank" rel="noopener noreferrer"><em>Photos</em></a>
//             </div>
//         </div>
//     );
     
// }
// const Volunteer1 = ()=> {
//     return(
//         <div id="aaa_volunteer1" className="T600 clearfix">
//             <div className="Vcol2 Vcol2L col2sizeL">
//                 <figure>
//                     <img id="aaa_orphanage" alt="" className="volunt responsive cursordefault" src="../../img/volunteering/aaa_volunteeringMain3.jpg" />
//                 </figure>
//             </div>
//             <div className="Vcol3 Vcol2R col2sizeR">
//                 <strong className="font14">New Horizons Orphanage</strong><br/><br/>In the Tocumen area, the orphanage, despite multiple needs, tries to be self-sufficient.  <br/> <br/> <strong>Contribution</strong>  <br/>Reconditioning of area for livestock to have an appropriate place to produce consumables, generating in this way a permanent income. Part of the space is devoted to corn planting, egg farming and goat cattle feed.  <br/> <a href="https://www.facebook.com/amadorarquitectos/posts/1247540582029996?__xts__%5B0%5D=68.ARAgFJ89OvK1BJesxpUYnA1OOJhLty-X4O1U5Bg_tA0PXTacIkZnh8GtpQkWGj8lKJySzDExDOTGU5Tc7xD9oBfSef4wacqNAMM4jFwb8ldnO5Dmjp_WyPsluUNzjAoMXODSUl8VT6ueG21OjLjdtiO8SksHGS1pL5fuyPe-33BiQfxz70GYV1NzkyvslWbo5wYBZ37Q1pRQPBpTdEp7oVD14Cjb-CwyS23QYgG8z7aajFP4bRlP7ANMhj_xyLXnejAM9DmwAyM0C6ieeFCKfx34vV0r6BHryImB3FglSGzhRwCvnw0Vi8SqBX11ZrknJOuoeh456ca2Udg8fhCkjLyeGRqPXhqis-R5JZyABxOeaVzxBmMcicpEekyaJrWtsai273uQrP4tEgBBkYKikDBeL7k5WzpsYUaPQYSU1zfwpSvmtY2Toy08n3j3vYNYBu19_QAoq_CXwBMwyZKv&amp;__tn__=-R" target="_blank" rel="noopener noreferrer"><em>Photos</em></a>
//             </div>
//         </div>    
//     );
// }

const myReusableComponent = (e,x,key)=>{
    return(
        <div id={e.id} className="T600 clearfix" key={key} style={{ marginBottom: 20 }} >
            <div className="Vcol2 Vcol2L col2sizeL">
                <figure>
                    <img id={e.imgID} alt="" className="volunt responsive cursordefault" src={e.imgSRC}  />
                </figure>
            </div>
            <div className="Vcol3 Vcol2R col2sizeR" dangerouslySetInnerHTML={{ __html: x === 1? e.eng_content:e.pt_content }} />
        </div>
    );    
}



class Volunteer extends Component {
    state = {  }
    componentDidMount(){
        // document.getElementById('aaaMiddle').style.maxWidth = "1200px";
    }
    render(props) { 
        // console.log(this.props.currentLang);
        return ( 
            <div id="aaa_VolunteeringContainer" className="T600  animEntrance">
                {/* <Volunteer4 currentLang={this.props.currentLang} /> */}
                {/* { myReusableComponent(contentStruct.item4, this.props.currentLang ) } */}
                { Object.keys(contentStruct).map((x,i) => myReusableComponent(contentStruct[x], this.props.currentLang, i) )}
                {/* <br/><br/>
                <Volunteer3 />
                <br/><br/>
                <Volunteer2 />
                <br/><br/>                
                <Volunteer1 />
                <br/><br/>                 */}
            </div>
        );
    }
}
 
export default Volunteer;